import React, { useEffect } from 'react';
import Header from "./Header";
import { useTranslation } from 'react-i18next';
import TermOfUseEN from '../lib/terms_en';
import TermOfUseKO from '../lib/terms_ko';

const TermOfUse = () => {

    const { t, i18n } = useTranslation(['translation']);

    useEffect(() => {
    }, [i18n.language]);

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper text-white">
                <div className="sub-header-none"></div>
            </section>
            {i18n.language === "en" && <TermOfUseEN />}
            {i18n.language === "ko" && <TermOfUseKO />}
        </>
    )
}

export default TermOfUse;
