import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import { DoDB } from "../lib/ddb";
import { SubscriptionConstant } from "../redux/reducer";

const SubRegisteredPcs = ({ sub, subs, subPc, subPcs }) => {
    const [t] = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [subRevoke, setSubRevoke] = useState({ show:false });
    const [items, setItems] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        setItems(subPcs);
    }, [subPcs, subPc]);

    // 해당 라이선스의 pllog 정보를 가져와 pcid 를 맵핑시켜 마지막 사용 시간을 표시 한다.
    const getUserPlLog = async () => {
        const appsLcuse = sub?.appsLcuse.filter(f => f.app == sub?.lastrenewdata?.product?.appitems[0]);
        const getPlLogParams = {
            TableName: "rayteams-license",
            KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
            ExpressionAttributeNames: {
                "#_id": "_id",
                "#sk": "sk"
            },
            ExpressionAttributeValues: {
                "_id": appsLcuse[0].app,
                "sk": `pllog:${appsLcuse[0].sk.replace("pl:", "")}`
            }
        }

    }

    const revokeLicense = async () => {
        setShowLoader(true);
        const now = new Date().getTime();
        const appsLcuse = sub?.appsLcuse.filter(f => f.app == sub?.lastrenewdata?.product?.appitems[0]);
        let subPc = subPcs.filter(f => f.rtid == subRevoke.pcId)[0];
        subPc = {
            ...subPc,
            revoked: true,
            updated: now,
            updator: user._id
        }

        const updatePlLogParams = {
            TableName: "rayteams-license",
            Key: {
                _id: appsLcuse[0].app,
                sk: `pllog:${appsLcuse[0].sk.replace("pl:", "")}:${now}`,
            },
            UpdateExpression: "SET #created = :created, #creator = :creator, #type = :type, #data = :data",
            ExpressionAttributeNames: {
                "#created": "created",
                "#creator": "creator",
                "#type": "type",
                "#data": "data",
            },
            ExpressionAttributeValues: {
                ":created": now,
                ":creator": user?._id,
                ":type": "subrevoke",
                ":data": {
                    "used" : `${user?.groupId}:${user?._id}`,
                    "pcid" : subRevoke.pcId
                },
            }
        }
        const updatePllog = await DoDB("update",updatePlLogParams);
        const updateLcuseParams = {
            TableName: "rayteams-license",
            Key: {
                _id: appsLcuse[0].app,
                sk: appsLcuse[0].lcKey
            },
            UpdateExpression: `SET used[${subRevoke.used}].revoked = :revoked, used[${subRevoke.used}].updated = :updated, used[${subRevoke.used}].updator = :updator`,
            ExpressionAttributeValues: {
                ":revoked": true,
                ":updated": now,
                ":updator": user._id
            }
        }
        const updateLcuse = await DoDB("update", updateLcuseParams);
        if(updatePllog && updateLcuse) {
            await dispatch({ type: SubscriptionConstant.UPDATE_REGISTERED_PCS, item: subPc });
            setSubRevoke({show: false})
        }

        setShowLoader(false);
    };



    return (<>
        {!loading && <>
        <h3 className="mt-5">{t('라이선스 등록 정보')}</h3>
        <div className="table-responsive mt-4" style={{
            borderTop: "1px solid #8574ff",
            borderColor: "#8574ff"}}>
        <div className="">
            <table className="table table-order">
                <thead>
                    <tr>
                        <th className="">{t("PC ID")}</th>
                        <th className="text-center">{t("PC Name")}</th>
                        <th className="text-center">{t("상태")}</th>
                        <th className="text-end">{t("등록 일시")}</th>
                        <th className="text-end">{t("최근 사용일")}</th>
                        {/* <th className="text-end">{t("해제 일시")}</th> */}
                    </tr>
                </thead>
                <tbody>
                    {subPcs.length === 0 && <tr>
                        <td className="text-center" colSpan="6">{t("등록 이력이 없습니다.")}</td>
                    </tr>}
                    {subPcs.length > 0 && subPcs.map((x, idx) => <tr key={"subPcs-" + idx}>
                        <td className=""><strong >{x.rtid}</strong></td>
                        <td className="text-center"><strong >{x.pcname}</strong></td>
                        <td className="text-center"><strong className={x?.revoked ? "text-red" : ""} >{x?.revoked ? t("등록 해제") : t("등록") }</strong></td>
                        <td className="text-end"><strong className="">{x?.created ? moment(x.created).format("LLL") : ""}</strong></td>
                        <td className="text-end"><strong className="">{x?.lastused ? moment(x.lastused).format("LLL") : ""}</strong></td>
                        {/* <td className="text-end">
                            {!x?.revoked ?
                                <button className="btn btn-danger" style={{ padding : "4px 8px" }} onClick={() => setSubRevoke({ show: true, used: idx, pcId: x.rtid })}>{t("해제")}</button>
                                : <strong className="">{x?.updated ? moment(x.updated).format("LLL") : ""}</strong>
                            }
                        </td> */}
                    </tr>)}
                </tbody>
            </table>
        </div>
        </div>
        {subRevoke.show && <>
                <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: subRevoke.show ? 'block' : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSubRevoke({ show : false })}></button>
                                <div className="">
                                    <div className="mb-3" style={{ fontSize : '30px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{t("라이선스 등록을 해제 하시겠습니까?")}</div>
                                    <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{t("등록 해제 시 해당 PC는 라이선스를 사용 할 수 없습니다.")}</div>
                                </div>
                                <div className="mt-6">
                                    <div className="justify-content-end d-flex">
                                        <button className="btn btn-sm btn-gray py-2" onClick={() => setSubRevoke({ show : false })} style={{ borderRadius: "24px" }}>{t("닫기")}</button>
                                        <button className="btn btn-sm btn-primary ms-2 py-2" onClick={() => revokeLicense()} style={{ borderRadius: "24px" }}>{t("등록 해제")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{ display: subRevoke.show ? 'block' : "none" }}></div>
            </>}
        </>}
            <Loader isShow={showLoader} />
        </>
        
    )
}

const mapState = (state) => {
    const sub = state.SubscriptionReducer.sub;
    const subs = state.SubscriptionReducer.subs;
    const subPc = state.SubscriptionReducer.subPc;
    const subPcs = state.SubscriptionReducer.subPcs;
    return { sub, subs, subPc, subPcs }
};

const mapDispatch = dispatch => ({
   
});

export default connect(mapState, mapDispatch)(SubRegisteredPcs);
