import { history } from "../history";
import Header from "./Header";
import { useTranslation } from 'react-i18next';

const NoApps = () => {
    const [t] = useTranslation(['translation']);

    return (
        // <div className="content-wrapper rt-site bg-gray">
        <>
            <Header />
            <section className="wrapper">
                <div className="container pt-14 pt-md-17 pb-10">
                    <div className="row">
                        <div className="col-lg-9 col-xl-8 mx-auto">
                            <figure className="mb-4 mt-4 text-center"><img className="img-fluid w-24" src="./assets/img/img_not_found.png" srcSet="./assets/img/img_not_found.png" alt="Page Not Found" /></figure>
                        </div>
                        <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                            <h1 className="mb-3">{t('페이지가 존재하지 않습니다.')}</h1>
                            <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">
                                {t('죄송합니다, 요청하신 URL을 찾을 수 없습니다.')}<br />
                                {t('아래 버튼을 클릭하여 메인페이지로 이동하세요.')}
                            </p>
                            <a className="btn btn-primary rounded-pill" onClick={() => history.push('/')}>{t('메인페이지로 이동')}</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
        // </div>
    )
}

export default NoApps;