import { useEffect } from "react";
import { useLocation } from "react-router";
import Loader from "./Loader"
import queryString from 'query-string';
import { PaymentsAction } from "../redux/action";
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { AppConfig } from "../config";

const PaymentsBefore = () => {
    const { search } = useLocation();
    const q = queryString.parse(search);
    useEffect(async () => {
        const token = q.token;        
        const data = JSON.parse(q.data);

        const pg = data.pg;

        const userInfo = JSON.parse(atob(token.split('.')[1]));

        const user_id = userInfo["cognito:username"];
        const user_name = userInfo["name"];
        const email = userInfo["email"];
        const product_id = data.product_id;

        const userData = await PaymentsAction.GetUserInfo(user_id);
        const countryCode = userData.countryCode;

        if (pg === 'paypal') {
            const plan_id = data.price_id;
            const response = await PaymentsAction.CreateBillingSub(token, {
                plan_id,
                product_id,
                userInfo: {
                    _id: user_id,
                    countryCode,
                    email
                },
                returnUrl: "http://localhost:3000"
            });

            const redirect = response?.data?.links?.find(x => x.rel === "approve")?.href;

            if (redirect)
                location.href = redirect;
        }

        if (pg === 'toss') {

            const user_info = encodeURIComponent(JSON.stringify({ user_id, countryCode, email }));

            loadTossPayments(AppConfig.TOSS_CLIENT_KEY).then(tossPayments => {
                tossPayments.requestBillingAuth('카드', {
                    customerKey: user_id,
                    successUrl: `${location.origin}/payments-result?pg=toss&result=success&product_id=${product_id}&user_info=${user_info}`, // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
                    failUrl: `${location.origin}/payments-result?pg=toss&result=fail&product_id=${product_id}&user_info=${user_info}`,       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
                }).catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        location.href = '/';
                    }
                })
            })
        }
    }, []);

    return (
        <Loader isShow={true} />       
    )
}

export default PaymentsBefore;