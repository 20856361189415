import { useEffect, useState } from "react";
import SignUpStep3 from "./SignUpStep3";
import SignUpSelectType from "./SignUpSelectType";
import SignUpForm from "./SignUpForm";
import SignUpComplete from "./SignUpComplete";

const SignUp = () => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        ordId: '',
        countryCode: '',
        region: '',
        grouptype: [],
        groupId: '',
        type: [],
        termpol: []
    });

    const [officeData, setOfficeData] = useState({
        grouptype: [],
        countryCode: '',
        name: '',
        address: '',
        zipcode: '',
        tel: '',
        coords: [],
        region: ''
    });

    const [htmlContents, setHtmlContents] = useState({ terms: '', poilicy: '', baadpa: '' });
    const [singUpComplete, setSignUpComplete] = useState(false);

    return (
        <>
            {(officeData.grouptype.length === 0 && !singUpComplete) && <SignUpSelectType officeData={officeData} setOfficeData={(data) => setOfficeData(data)} />}

            {
                (officeData.grouptype.length > 0 && !singUpComplete) &&
                <SignUpForm
                    userData={userData}
                    setUserData={(data) => setUserData(data)}
                    officeData={officeData}
                    setOfficeData={(data) => setOfficeData(data)}
                    htmlContents={htmlContents}
                    setHtmlContents={(data) => setHtmlContents(data)}
                    setSignUpComplete={(data) => setSignUpComplete(data)}
                />
            }

            {singUpComplete && <SignUpComplete />}
        </>
    )
}

export default SignUp;