
export const BUY_TYPE = {
    SUBSCRIPTION: "sub",
    PURCHASE: "buy",
};
export const BUY_TYPE_LABEL = {
    [BUY_TYPE.SUBSCRIPTION]: "Subscription",
    [BUY_TYPE.PURCHASE]: "Purchase",
};
export const PAYMENT = {
    PAYPAL: "paypal",
    TOSS: "toss",
}
export const PAYMENT_LABEL = {
    [PAYMENT.PAYPAL]: "Paypal",
    [PAYMENT.TOSS]: "Toss",
};

export const PERIOD_UNIT = {
    d: 1,
    w: 7,
    m: 30,
    y: 365,
};

export const PRICEUNIT = [ 
	{ value : "KRW", label: "₩", kLabel: "원" },
	{ value : "USD", label: "$", kLabel: "달러" },
	{ value : "EUR", label: "€", kLabel: "유로" },
	{ value : "JPY", label: "円", kLabel: "엔" },
	{ value : "GBP", label: "£", kLabel: "파운드" },
	{ value : "CNY", label: "¥", kLabel: "위안" },
]

export const CURRENCY_STR = {
    USD: { symbol: "$", string: "dollars", default: "$" },
    KRW: { symbol: "₩", string: "원", default: "원" },
    EUR: { symbol: "€", string: "euro", default: "€" },
    JPY: { symbol: "円", string: "円", default: "円" },
    GBP: { symbol: "£", string: "pounds", default: "£" },
    CNY: { symbol: "¥", string: "元", default: "¥" },
};
