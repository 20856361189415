import Header from "./Header"
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { AppAction } from "../redux/action";
import { useParams } from "react-router-dom";
import AppHeader from "./AppHeader";
import { useTranslation } from 'react-i18next';
import { history } from "../history";
import { ProgramConstant } from "../redux/reducer";
import { convertHnElsToTreeByHTMLString } from "../lib/domFunction";
import { getRealAppName } from "../lib/function";

const ReleasenotesRC = ({ appTitle, appIcon, releasenotes, GetAppInfo, GetReleaseNotes }) => {
    const { t, i18n } = useTranslation(['translation']);
    const [lang, setLang] = useState(i18n.language || "en");
    const [contents, setContents] = useState({});
    const [list, setList] = useState([]);
    const [aInfo, setAInfo] = useState({});
    const { appCode, version } = useParams();
    const [summaryArr, setSummaryArr] = useState([]);
    const dispatch = useDispatch();
    const [realAppName] = useState(getRealAppName(appCode));

    useEffect(() => {
        dispatch({ type: ProgramConstant.VISIBLE_FOOTER, bool: false });
        return () => {
            dispatch({ type: ProgramConstant.VISIBLE_FOOTER, bool: true });
        };
    }, []);

    useEffect(async () => {
        setAInfo(await AppAction.GetAppInfoOnly(realAppName || 'RayLink'));
        GetReleaseNotes(realAppName || 'RayLink', lang, version.replace("v", ""));
    }, [realAppName, lang, version]);

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        if (releasenotes && releasenotes.length > 0) {
            setList(releasenotes?.map(x => {
                const { hierarchies, htmlString, elCount } = convertHnElsToTreeByHTMLString(x.contents);
                const result = h2ReplacedContents(htmlString);
                const summary = getSummary(result.contents);
                return {
                    ...x,
                    contents: x.contents ? summary.contents : null, // 존재하지 않는 contents의 경우 null로 적용함
                    subTitle: result.subTitle,
                    summary: summary.summary,
                    hierarchies,
                    elCount,
                };
            }));
        }
    }, [releasenotes]);

    useEffect(() => {
        if (list?.length > 0) {
            if (version) {
                if (list.find(x => x.version === version)) {
                    setContents(list.find(x => x.version === version));
                } else {
                    history.push(((appCode === 'RayLink' || !appCode) ? '' : '/' + appCode) + "/releasenotes");
                }
            } else {
                setContents(list[0]);
            }
        }
    }, [list, version]);

    const select = (v) => {
        const path = ((appCode === 'RayLink' || !appCode) ? '' : '/' + appCode) + '/releasenotes/' + v;
        history.push(path);
    }

    const h2ReplacedContents = (contents) => {
        const div = document.createElement("div");
        div.innerHTML = contents;
        const h2 = div.getElementsByTagName("h2");

        const subTitle = [];

        for (let i = 0; i < h2.length; i++) {
            if (h2[i].innerText.trim() !== '') {
                const id = h2[i].id || ('sub_title_' + i)
                h2[i].id = id;
                subTitle.push({ id, title: h2[i].innerText });
            }
        }

        return { contents: div.outerHTML, subTitle }
    }

    const getSummary = (contents) => {
        const div = document.createElement("div");
        div.innerHTML = contents;
        const h3 = div.getElementsByTagName("h3");

        const summary = [];

        for (let i = 0; i < h3.length; i++) {
            if (h3[i].innerText.trim() !== '') {
                const id = h3[i].id || ('summary_' + i)
                h3[i].id = id;
                summary.push({ id, title: h3[i].innerText });
            }
        }

        return { contents: div.outerHTML, summary };
    }

    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: 'url("/assets/img/bg1.jpg")' }}>
                <div className="container p-4">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                {aInfo?.app_icon?.direct_link && <img className="avatar" src={aInfo?.app_icon?.direct_link} alt={appTitle || 'RAYTeams Client'} />}
                                {!aInfo?.app_icon?.direct_link && <div style={{ height: 56, width: 56 }}></div>}
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{aInfo?.title || '...'}</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('릴리즈 노트')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container manual">
                <div className="row">
                    {releasenotes?.length > 0 && contents?.version && <div className="order-2 pt-md-4 pb-5">
                        <section className="wrapper">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">{appTitle || 'RAYTeams Client'}</a></li>
                                    <li className="breadcrumb-item"><a href="#">{t('릴리즈 노트')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{contents.version}</li>
                                </ol>
                            </nav>
                            <div className="row gx-lg-8 gx-xl-12 my-2 tit-release">
                                <div className="col-lg-8">
                                    <h2 className="mb-1">
                                        {contents?.title || `v${contents?.version} Release Note`}
                                        {/* {contents.title + ' '} */}
                                        {/* {!contents.title?.includes(contents?.version?.replace("v", "")) && <>
                                            <span className="text-muted" style={{ fontSize: 16 }}>{contents.version}</span>
                                        </>} */}
                                        {/* {
                                            contents.version === releasenotes[0]?.version &&
                                            <span className="badge bg-dark rounded-pill badge-xs">RC Version</span>
                                        } */}
                                    </h2>
                                    <p className="mt-2 mb-4">
                                        {contents?.contents && <>
                                            {t('사용 편의성 증대를 위해 다음과 같은 기능이 추가 또는 변경되었습니다.')}<br />
                                            {t('변경된 내용을 확인 후 사용해 주세요.')}
                                        </>}
                                        {!contents?.contents && <>
                                            {t("Minor bugs have been fixed and usability has been improved.")}
                                        </>}
                                    </p>
                                </div>
                            </div>
                            {contents?.contents && <>
                                <div className="board-content">
                                    <div className="card-body">
                                        {contents?.hierarchies?.length > 0 && contents?.elCount > 1 && <>
                                            <div className="card transparent bg-gray mb-8">
                                                <div className="card-body text-list">
                                                    <ol style={{ marginBottom: 0, listStyleType: 'none', paddingLeft: '1rem' }}>
                                                        {
                                                            contents.hierarchies?.map((x, idx) => {
                                                                return (
                                                                    <li key={`s_${x.id}`}>
                                                                        <a href={`#${x.id}`}>{x.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                        {x?.children?.length > 0 && <>
                                                                            <ol style={{ listStyleType: 'none', paddingLeft: '1rem' }}>
                                                                                {x.children.map(y => <li key={y.id}>
                                                                                    <a href={`#${y.id}`}>{y.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                                    {y?.children?.length > 0 && <>
                                                                                        <ol style={{ listStyleType: 'none', paddingLeft: '1rem' }}>
                                                                                            {y.children.map(z => <li key={z.id}>
                                                                                                <a href={`#${z.id}`}>{z.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                                            </li>)}
                                                                                        </ol>
                                                                                    </>}
                                                                                </li>)}
                                                                            </ol>
                                                                        </>}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ol>
                                                </div>
                                            </div>
                                        </>}
                                        {/* {
                                        contents.summary?.length > 0 &&
                                        <div className="card transparent bg-gray mb-8">
                                            <div className="card-body text-list">
                                                <ol style={{ marginBottom: 0, listStyleType: 'none', paddingLeft: '1rem' }}>
                                                    {
                                                        contents.summary?.map((x, idx) => {
                                                            return (
                                                                <li key={`s_${idx}`}><a href={`#${x.id}`}>{x.title}<i className="uil uil-angle-double-right"></i></a></li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </div>
                                        </div>
                                    } */}

                                        <div dangerouslySetInnerHTML={{ __html: contents.contents }}></div>
                                    </div>
                                </div>
                            </>}
                        </section>
                        {/* <hr /> */}
                        <div className="quick-btn" style={{ display: 'none' }}>
                            <div className="row g-6">
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Check out the latest additions and updates to RAYTeams.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/community/" className="text-white" target="_blank">What's new</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Learn how to use RAYTeams and get answers to your questions.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/help-center/" className="text-white" target="_blank">Help Center</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Still have more questions? Please contact us.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/contact/#inquiry" className="text-white" target="_blank">1:1 Inquiry</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    return {
        appTitle: state.AppReducer.appInfo.appTitle,
        appIcon: state.AppReducer.appInfo.appIcon,
        releasenotes: state.AppReducer.releasenotesRC
    };
}

const mapDispatch = dispatch => ({
    GetAppInfo: (appCode) => dispatch(AppAction.GetAppInfo(appCode, 'ko')),
    GetReleaseNotes: (appCode, lang, version) => dispatch(AppAction.GetReleaseNotesRC(appCode, lang, version))
})

export default connect(mapState, mapDispatch)(ReleasenotesRC);
