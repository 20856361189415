import { history } from "../history";
import Header from "./Header";
import { useTranslation } from 'react-i18next';

const SmileNation = () => {
    const [t] = useTranslation(['translation']);

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="./assets/img/bg1.jpg" style={{ backgroundImage: "url('./assets/img/bg1.jpg')" }}>
                <div className="sub-header-none"></div>
            </section>
            <section className="wrapper bg-gray">
                <div className="container pt-12 pt-md-16 text-center">
                    <div className="row">
                        <div className="col-lg-8 col-xxl-8 mx-auto text-center" data-group="page-title">
                            <h1 className="display-1 fs-58 mb-7" style={{ color: "#333" }}>Join our Smile Nation</h1>
                            <p className="lead fs-lg lh-sm mb-4 pe-xl-10" style={{ color: "#8c73bf" }}>
                                And share your thoughts &amp; feedback with global users and our R&amp;D team!
                            </p>
                            <p className="fs-md lh-sm mb-8 pe-xl-10">
                                We are excited to embark on this journey together and create a vibrant community at Smile Nation Group.<br />
                                Let&#39;s embrace this opportunity to shape a brighter future!
                            </p>
                            <div className="d-flex justify-content-center mb-5 mb-md-0"data-group="page-title-buttons">
                                <span><a href="https://www.facebook.com/groups/240359421728932" className="btn btn-lg btn-violet rounded-pill" target="_blank"><i className="uil uil-facebook"></i><span className="ms-2">Visit Ray Smile Nation</span></a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className="position-absoute" style={{ 
                    bottom: 0, 
                    left: 0, 
                    zIndex: 2
                }}><img src="/assets/img/bg-smile-nation.jpg" alt="Smile Nation" /></figure>
            </section>
            <section className="wrapper bg-gray">
                <div className="container">
                    <div className="card shadow-none my-n4 my-lg-n13">
                        <div className="card-body py-12 py-lg-14 px-lg-11 py-xl-14 px-xl-13 pb-xl-8">
                            <div className="row text-center">
                                <div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto">
                                    <h2 className="fs-15 text-uppercase text-muted mb-3" style={{color:"#c9c2d7 !important"}}>What We Do?</h2>
                                    <h3 className="display-4 mb-9" style={{color:"#8c73bf"}}>Here's what you can look forward to in Smile Nation Group:</h3>
                                </div>
                            </div>
                            <div className="row gx-md-8 gx-xl-12 gy-8 mb-10">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-block btn-lg btn-soft-purple pe-none mt-1 me-5"><span className="number fs-22">01</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1">Ray's Brand Vision</h4>
                                            <p className="mb-0">Discover our compelling brand vision that drives Smile Nation forward.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row mt-8">
                                        <div>
                                            <span className="icon btn btn-block btn-lg btn-soft-green pe-none mt-1 me-5"><span className="number fs-22">02</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1">Clinical Information with Ray's Solutions</h4>
                                            <p className="mb-0">Explore the latest clinical information and advancements made possible through Ray's innovative solutions.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row mt-8">
                                        <div>
                                            <span className="icon btn btn-block btn-lg btn-soft-orange pe-none mt-1 me-5"><span className="number fs-22">03</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1">Active Engagement Opportunities</h4>
                                            <p className="mb-0">Engage in lively discussions by posting, commenting, and participating in discussions within our community.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex flex-row ">
                                        <div>
                                            <span className="icon btn btn-block btn-lg btn-soft-aqua pe-none mt-1 me-5"><span className="number fs-22">04</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1">Upcoming Webinars &amp; Seminars</h4>
                                            <p className="mb-0">Stay in the loop with rapid updates on our upcoming webinars and seminars, ensuring you never miss out on valuable information.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row mt-8">
                                        <div>
                                            <span className="icon btn btn-block btn-lg btn-soft-yellow pe-none mt-1 me-5"><span className="number fs-22">05</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1">Detailed Technical Updates &amp; Guidelines</h4>
                                            <p className="mb-0">Get access to comprehensive videos and guidelines that will assist you in effectively utilizing our software and hardware, including RAYFace.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-10 my-md-12" />
                            <div className="wrapper image-wrapper bg-auto no-overlay bg-image text-center bg-map" data-image-src="/assets/img/map.png" style={{backgroundImage: "url(/assets/img/map.png)" }}>
                                <div className="container py-md-14 py-lg-16">
                                    <div className="row">
                                        <div className="col-xl-11 col-xxl-9 mx-auto">
                                            <h3 className="display-4 mb-8 px-lg-8">Join our Smile Nation and<br/>share your thoughts &amp; feedback!</h3>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <span><a href="https://www.facebook.com/groups/240359421728932" target="_blank" className="btn btn-violet rounded-pill btn-lg"><i className="uil uil-facebook"></i><span className="ms-2">Visit Ray Smile Nation</span></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-gray pt-0 pt-md-4">
                <div className="container py-4 py-lg-10"> &nbsp; </div>
            </section>
        </>
        // </div>
    )
}

export default SmileNation;
