const initialState = { 
    appInfo : {},
    appList : [],
    featuredAppList : [],
    releasenotes : [],
    baadpaInfos: [],
    baadpaInfo: {},
};

export const AppConstant = {
    GET_APP_INFO: 'GET_APP_INFO',
    GET_RELEASE_NOTES: 'GET_RELEASE_NOTES',
    GET_RELEASE_NOTES_RC: 'GET_RELEASE_NOTES_RC',
    GET_MANUAL: 'GET_MANUAL',
    GET_APP_LIST: 'GET_APP_LIST',
    GET_FEATURED_APP_LIST: 'GET_FEATURED_APP_LIST',
    GET_BAADPA_INFOS: 'GET_BBADPA_INFOS',
    GET_BAADPA_INFO: 'GET_BBADPA_INFO',
};

export const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case AppConstant.GET_APP_INFO:
            return {...state, appInfo : action.data}

        case AppConstant.GET_RELEASE_NOTES:
            return { ...state, releasenotes: action.data }

        case AppConstant.GET_RELEASE_NOTES_RC:
            return { ...state, releasenotesRC: action.data }

        case AppConstant.GET_MANUAL:
            return { ...state, manual: action.data }

        case AppConstant.GET_APP_LIST:
            return { ...state, appList: action.data }

        case AppConstant.GET_FEATURED_APP_LIST:
            return { ...state, featuredAppList: action.data }
        case AppConstant.GET_BAADPA_INFOS:
            return { ...state, baadpaInfos: action.data }
        case AppConstant.GET_BAADPA_INFO:
            return { ...state, baadpaInfo: action.data }
        default:
            return state;
    }
}
