import { useEffect } from 'react';
import { connect } from "react-redux";
import { BoardAction } from '../../redux/action/BoardAction';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const SupportNotice = () => {

    const { t, i18n } = useTranslation(['translation']);
    const [item, setItem] = useState({});

    useEffect(() => {
        getData();
    }, [i18n]);

    const getEnv = () => {
        if (location.href.indexOf("localhost") > -1) {
            return "dev-local";
        } else if (location.href.indexOf("raydevelop") > -1) {
            return "development";
        }
        return "production";
    }

    const getData = async () => {
        const bid = getEnv() === "production" ? "51d7aa2b-1739-40cd-b220-d7db043fd27d" : "9e02c214-9d9f-4361-81ee-b6231c020348";
        const data = await BoardAction.GetList(bid);
        console.log(data.filter(x => x.sk.indexOf(":" + i18n.language) > -1).sort((a, b) => b.updated - a.updated));
        console.log(data.filter(x => x.sk.indexOf(":" + i18n.language) > -1).sort((a, b) => b.updated - a.updated)[0]);
        setItem(data.filter(x => x.sk.indexOf(":" + i18n.language) > -1).sort((a, b) => b.updated - a.updated)[0]);
    }

    return <section className="wrapper notice">
        <div className="container py-3 pt-2">
            <div className="row">
                <div className="col-lg-10">
                    <span className="badge rounded-pill">{t("Notice")}</span><span>
                        <a href style={{ cursor : "pointer" }} onClick={() => history.push("/board/view/" + item?._id)} className="ms-2 hover fs-14">{item?.title}</a>
                    </span>
                </div>
                <div className="col-lg-2 text-end">
                    <a href style={{ cursor : "pointer" }} onClick={() => history.push(getEnv() === "production" ? "/board/51d7aa2b-1739-40cd-b220-d7db043fd27d/list" : "/board/9e02c214-9d9f-4361-81ee-b6231c020348/list")} className="ms-2 hover fs-14">{t("더 보기")}</a>
                </div>
            </div>
        </div>
    </section>
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(SupportNotice);
