import { useEffect } from 'react';
import { AppAction } from '../../redux/action';
import Header from '../Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../../lib/function';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../../constant/appconst';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoardAction } from '../../redux/action/BoardAction';

const SupportSection = ({ appList, GetAppList }) => {
    const { t, i18n } = useTranslation(['translation']);
    const { section } = useParams();
    const [ selected, setSelected] = useState({});
    const [ sec, setSec] = useState({});
    const [ subsec, setSubSec] = useState([]);
    const [ items, setItems] = useState([]);

    useEffect(() => {
        getData()
    }, [section, i18n.language]);

    useEffect(() => {
    }, [selected.sk]);

    const getData = async () => {
        console.log(section);
        const ret = await BoardAction.GetSupportBoardSection(section, i18n.language);
        const retItems = await BoardAction.GetSupportBoardSectionItems(section, i18n.language);
        setSubSec(ret.filter(x => x.sk !== section + ":" + i18n.language));
        console.log(retItems);
        setItems(retItems);
        setSec(ret.find(x => x.sk === section + ":" + i18n.language));
        if(ret.filter(x => x.sk !== section + ":" + i18n.language).length > 0)
            setSelected(ret.filter(x => x.sk !== section + ":" + i18n.language)[0]);
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white help-center" style={{ backgroundImage: "url('../assets/img/bg1.jpg')"}} >
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src="../assets/img/app-icon/img_rayteams.png" alt="rayteams" />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">RAYTeams Cloud</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t("Help Center")}</h3>
                                </div>
                        </div>
                        <div className="col-lg-3">
                            {/*<div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label for="">Search...</label>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-soft-primary">
                <div className="container pt-12 pb-10">
                    <div className="row gx-lg-8 gx-xl-12 gy-10">
                        <div className="col-lg-6 mb-0">
                            <p className="mb-6"><a href="#" onClick={() => history.push("/support")} className="text-purple hover"><i className="uil uil-arrow-left"></i><span className="ms-1">Help Center Home</span></a></p>
                            <h3 className="display-2 mb-4">{sec.title}</h3>
                            <p className="lead">{sec.content}</p>
                        </div>
                        <div className="col-lg-6">
                            {items.sort((a, b) => a.read > b.read).slice(0, 3).map((x, idx) => <div className="card mb-3" key={idx} style={{ cursor : "pointer"}} onClick={() => history.push("/support/view/" + x._id)}>
                                <div className="card-header">{x.title}</div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container pt-11 pb-11">
                    <ul className="nav nav-tabs nav-tabs-bg d-flex justify-content-between nav-justified flex-lg-row flex-column help-category">
                        {subsec.map((x, idx) => <li className="nav-item" key={idx}>
                            <a className={"nav-link d-flex flex-row " + (x.sk === selected.sk ? "active" : "")} data-bs-toggle="tab" onClick={() => setSelected(x)} href="#tab2-1">
                                <div>
                                    <h4>{x.title}</h4>
                                    <p>{x.contents}</p>
                                </div>
                            </a>
                        </li>)}
                    </ul>
                    <div className="tab-content mt-6 mt-lg-4">
                        <div className="tab-pane fade show active" id="tab2-1">
                            <div className="row gx-lg-8 gx-xl-10 gy-0 align-items-center">
                                {items.filter(x => x.menu + ":" + i18n.language === selected.sk).map((x, idx) => <div className="col-lg-6">
                                        <a href="#" 
                                            onClick={() => history.push("/support/view/" + x._id)}
                                            className="card mb-4 lift" key={idx}>
                                        <div className="card-body p-5">
                                            <span className="row justify-content-between align-items-center">
                                                <span className="col-md-11 mb-2 mb-md-0 d-flex align-items-center text-body text-truncate">{x.title}</span>
                                                <span className="d-none d-lg-block col-1 text-center text-body">
                                                    <i className="uil uil-angle-right-b"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </a>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="wrapper">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <p className="text-center"><img src="/assets/img/logo-light.png" className="rounded-2 w-10" alt="RAYTeams" /></p>
                            <h2 className="display-6 mb-6 text-center">All services to dental, RAYTeams.</h2>
                            <p className="text-center mb-6 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p className="text-center"><a className="btn btn-md btn-primary rounded-pill text-white text-decoration-none mb-1" onClick={(e) => moveTo(history, e, "/aboutcloud")}>RAYTeams Download</a> </p>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(SupportSection);
