import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getApiUrl } from "../lib/function";
import axios from "axios";
import { useTranslation } from "react-i18next";

const VERIFY_EMAIL_LOCAL_STORAGE_KEY = "__verifyEmailEvent"; // 브라우저의 axios 이벤트 관련 로컬 스토리지 저장 키
const EVENT_ROOF_GAP = 1000 * 10; // 현재 브라우져가 verify email 이벤트를 반복해서 발생 시키는경우 반복 발생의 최소 갭 ms
const PAGE_ERROR_CODE = {
  NONE_QUERY: "NONE_QUERY",
  NONE_JSON_QUERY: "NONE_JSON_QUERY",
  WRONG_QUERY: "WRONG_QUERY",
  TOO_FAST_CHECK: "TOO_FAST_CHECK",
  API_NO_PARAMS: "API_NO_PARAMS",
  API_NOT_FIND_USER: "API_NOT_FIND_USER",
  API_NONE_VERIFICATION: "API_NONE_VERIFICATION",
  API_WRONG_EMAIL: "API_WRONG_EMAIL",
  API_WRONG_HASH: "API_WRONG_HASH",
  API_EXPIRED: "API_EXPIRED",
  API_SERVER_ERROR: "API_SERVER_ERROR"
};
const API_ERROR_MAP = {
  "NO_PARAMS": PAGE_ERROR_CODE.API_NO_PARAMS,
  "NOT_FIND_USER": PAGE_ERROR_CODE.API_NOT_FIND_USER,
  "NONE_VERIFICATION": PAGE_ERROR_CODE.API_NONE_VERIFICATION,
  "WRONG_EMAIL": PAGE_ERROR_CODE.API_WRONG_EMAIL,
  "WRONG_HASH": PAGE_ERROR_CODE.API_WRONG_HASH,
  "EXPIRED": PAGE_ERROR_CODE.API_EXPIRED,
  "default": PAGE_ERROR_CODE.API_SERVER_ERROR,
};
const PAGE_STATUS = {
  INIT: "INIT",
  ERROR: "ERROR",
  READY: "READY",
  COMPLETE: "COMPLETE",
};

const EmailVerify = () => {
  const { t, i18n } = useTranslation(['translation']);
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [hash, setHash] = useState("");
  const [pageStatus, setPageStatus] = useState(PAGE_STATUS.INIT);
  const [pageErrorCode, setPageErrorCode] = useState("");

  // Footer hide
  useEffect(() => {
    const footer = window.document.getElementById("teams-homepage-footer")
    if (footer) {
      footer.style.display = "none";
    }
    return () => {
      if (footer) {
        footer.style.display = "";
      }
    };
  }, []);

  // Find data and set page status
  useEffect(() => {
    setPageErrorCode("");
    setPageStatus("");
    try {
      const params = new URLSearchParams(location?.search)
      const rowData = params.get("d");
      if (!rowData) {
        setPageErrorCode(PAGE_ERROR_CODE.NONE_QUERY)
        setPageStatus(PAGE_STATUS.ERROR);
        return;
      }
      const data = JSON.parse(atob(rowData));
      if (!data?.u || !data.e || !data.h) {
        setPageErrorCode(PAGE_ERROR_CODE.WRONG_QUERY)
        setPageStatus(PAGE_STATUS.ERROR);
        return;
      }
      setUserId(data.u);
      setEmail(data.e);
      setHash(data.h);
      try {
        const eventAt = JSON.parse(atob(window?.localStorage?.getItem(VERIFY_EMAIL_LOCAL_STORAGE_KEY) || "e30="))?.eventAt;
        const now = new Date().getTime();
        if (eventAt && now < (eventAt + EVENT_ROOF_GAP)) {
          setPageErrorCode(PAGE_ERROR_CODE.TOO_FAST_CHECK)
          setPageStatus(PAGE_STATUS.ERROR);
          return;
        }
      } catch (error) {
        //
      }
      setPageStatus(PAGE_STATUS.READY);
    } catch (error) {
      setPageErrorCode(PAGE_ERROR_CODE.NONE_JSON_QUERY)
      setPageStatus(PAGE_STATUS.ERROR);
    }
  }, [location?.search]);

  // Request verify email
  const verifyEmail = useCallback(async (userId, email, hash) => {
    try {
      const apiUrl = getApiUrl("user");
      const params = { data: { _id: userId, email, hash } };
      const ret = await axios.post(`${apiUrl}/user/${userId}/email/verify`, params);
      window?.localStorage?.setItem(VERIFY_EMAIL_LOCAL_STORAGE_KEY, btoa(JSON.stringify({ eventAt: new Date().getTime() })));
      if (ret?.data?.status === "success") {
        setPageStatus(PAGE_STATUS.COMPLETE);
        return;
      }
      const _errorCode = API_ERROR_MAP[ret?.data?.error || "default"] || API_ERROR_MAP.default;
      setPageErrorCode(_errorCode);
      setPageStatus(PAGE_STATUS.ERROR);
    } catch (error) {
      console.log("ERROR[]", error?.response?.data || error.toString());
      setPageErrorCode(API_ERROR_MAP.default);
      setPageStatus(PAGE_STATUS.ERROR);
    }
  }, []);

  useEffect(() => {
    switch (pageStatus) {
      case PAGE_STATUS.READY:
        if (userId && email) {
          verifyEmail(userId, email, hash);
        }
        break;
      case PAGE_STATUS.ERROR:
        // TODO: 로그 저장
        break;
      default:
        break;
    }

  }, [
    pageStatus,
    verifyEmail,
    userId,
    email,
    hash,
  ]);

  return <>
    <div
      className="w-100 h-100 d-flex align-items-center justify-content-center flex-column"
      style={{
        backgroundColor: "#1f2239",
      }}
    >
      <div className="text-center">
        <div className="mb-5">
          <img className="logo-light" src="/assets/img/logo-light.png" srcSet="/assets/img/logo-light@2x.png 2x" alt="RAYTeams" />
        </div>
        <h3 className="text-white mb-2">
          {pageStatus === PAGE_STATUS.COMPLETE && <>
            {t("Email verification has been completed.")}
          </>}
          {pageStatus === PAGE_STATUS.ERROR && <>
            {t("Email verification failed.")}
          </>}
          {[PAGE_STATUS.INIT, PAGE_STATUS.READY].includes(pageStatus) && <>
            {t("Email verification")}
          </>}
        </h3>
        <div className="text-muted">
          {[PAGE_STATUS.INIT, PAGE_STATUS.READY].includes(pageStatus) && <>
            <div>
              {t("We are currently in the process of email verification.")}
            </div>
          </>}
          {pageStatus === PAGE_STATUS.COMPLETE && <>
            <div>
              {t("Verification has been completed. You may now close this window.")}
            </div>
          </>}
          {pageStatus === PAGE_STATUS.ERROR && <>
            <div>
              {[PAGE_ERROR_CODE.WRONG_QUERY, PAGE_ERROR_CODE.NONE_QUERY].includes(pageErrorCode) && <>
                {t("You have accessed this page through an incorrect path.")}
              </>}
              {[PAGE_ERROR_CODE.NONE_JSON_QUERY, PAGE_ERROR_CODE.API_NO_PARAMS].includes(pageErrorCode) && <>
                {t("You have accessed this page through an invalid method.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.TOO_FAST_CHECK && <>
                {t("Please try again in a moment.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_EXPIRED && <>
                {t("The email verification time has expired. Please request another verification email.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_NONE_VERIFICATION && <>
                {t("There is no requested verification.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_NOT_FIND_USER && <>
                {t("We couldn't find the user who requested the verification.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_SERVER_ERROR && <>
                {t("An error has occurred. Please try again later.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_WRONG_EMAIL && <>
                {t("This email is different from the one you requested.")}
              </>}
              {pageErrorCode === PAGE_ERROR_CODE.API_WRONG_HASH && <>
                {t("The current request is incorrect. If you have requested email verification multiple times, please check the latest email.")}
              </>}
            </div>
          </>}
        </div>
      </div>
    </div>
  </>
};

export default EmailVerify;
