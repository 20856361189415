import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducer from './redux/reducer';

const loggerMiddleware = createLogger();

export const Store = createStore(
    RootReducer,
    applyMiddleware(
        thunkMiddleware,        
    )
);