import axios from 'axios';
import { JWT } from '../../config';
import { getApiUrl } from '../../lib/function';
import { AuthConstant, ProgramConstant, AlertConstant } from '../reducer';
import { Store } from '../../store';
import { GroupAction } from './GroupAction';
import { history } from "../../history";
import moment from 'moment';

const RayLinkHeaderNoAuth = {
	'x-ray-device': 'RAYLINK'
};

const needUpdatePW = (userinfo) => {
	const pUpdated = userinfo.passwordChanged || userinfo.created;
	const pperiod = window.rayteamsconfig?.userPwSecurity?.nextChangeNotificationPeriod;


	const now = new Date().getTime();
	if (now < userinfo.needpwupdate)
		return false;

	const targetDate = moment(pUpdated).add(pperiod, "days").valueOf();
	if (now >= targetDate)
		return true;

	return false;
}

const Login = (data) => async (dispatch) => {
	const API_URL = getApiUrl('user');
	dispatch({ type: ProgramConstant.API_REQUEST });
	const ret = await axios.post(API_URL + '/login', { data }, JWT());
	dispatch({ type: ProgramConstant.API_RESPONSE });

	if (ret.data.status === 'success') {
		if (ret.data.data?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
			dispatch({ type: AlertConstant.NOTICE, data: "Success", msg: '초대를 통하여 가입한 계정은 RAYTeams를 통하여 가입을 완료해주세요.', show: true });
		} else {
			if (needUpdatePW(ret.data.data.user)) {
				localStorage.setItem('tmpUserId', ret.data.data.user._id);
				localStorage.setItem('tmpUserRegion', ret.data.data.user.region);
				localStorage.setItem('tmpUserEmail', ret.data.data.user.email);
				localStorage.setItem('tmpUserToken', ret.data.data.jwt);
				history.push('/changepw');
				return;
			}

			if (ret.data.data.serverId) {
				localStorage.setItem('serverId', ret.data.data.serverId);
			}

			localStorage.setItem('userToken', ret.data.data.jwt);
			localStorage.setItem('MYLAB_AUTH_REFRESHTOKEN', ret.data.data.token.RefreshToken);

			var udatajson = ret.data.data.user;
			if (udatajson?.groupId) {
				const region = udatajson?.region || udatajson["custom:region"];
				const groupinfo = await getGroupInfoById(udatajson.groupId, region);
				if (groupinfo) {
					udatajson = { ...udatajson, groupinfo: groupinfo };
				}
			}
			udatajson = { ...udatajson, utype: udatajson.utype || ["manager"] };
			var udata = JSON.stringify(udatajson);
			localStorage.setItem('user', udata);

			localStorage.setItem('RAYLINK_USER_EMAIL', udatajson.email);
			localStorage.setItem('AUTO_LOGIN', data.autoLogin ? "Y" : "");

			dispatch({ type: AuthConstant.LOGIN_SUCCESS, user: udatajson, userToken: ret.data.data.jwt });
		}
	} else {
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
	}

	return ret.data;
};

const getGroupInfoById = async (_id, region) => {
	const API_URL = getApiUrl('group', region);
	const ret = await axios.get(API_URL + `/group/${_id}`, JWT());

	if (ret.data.status === 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const UpdatePassword = async (data, region) => {
	const _id = localStorage.getItem('tmpUserId');
	const API_URL = getApiUrl('user', region);
	const ret = await axios.post(API_URL + `/user/${_id}/password`, { data: data }, {
		headers: {
			token: localStorage.getItem('tmpUserToken'),
			'x-ray-device': 'WEB'
		}
	});
	return ret.data;
};

const ModifyPassword = async (data) => {
	const region = Store.getState().AuthReducer.user.region;
	const _id = Store.getState().AuthReducer.user._id;
	const API_URL = getApiUrl('user', region);
	const ret = await axios.post(API_URL + `/user/${_id}/password`, { data: data }, JWT());
	console.log(ret.data);
	return ret.data;
};

const ModifyPhone = async (data) => {
	const region = Store.getState().AuthReducer.user.region;
	const _id = Store.getState().AuthReducer.user._id;
	const API_URL = getApiUrl('user', region);
	const ret = await axios.post(API_URL + `/user/${_id}/phone`, { data: data }, JWT());
	console.log(ret.data);
	return ret.data;
};

const Logout = () => (dispatch) => {
	localStorage.removeItem('user');
	localStorage.removeItem('userToken');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('RAYLINK_USER_ID');
	localStorage.removeItem('RAYLINK_USER_EMAIL');
	localStorage.removeItem('MYLAB_AUTH_USER');
	localStorage.removeItem('MYLAB_AUTH_REFRESHTOKEN');
	localStorage.removeItem('AUTO_LOGIN');
	localStorage.removeItem('MYLAB_AUTH_TOKEN');
	localStorage.removeItem('RAYLINK_GROUPID');
	history.push("/");
	dispatch({ type: AuthConstant.LOGOUT });
};

const UserUpdate = (_id, info) => async () => {
	const data = { data: info };
	console.log("userupdate: ====", data);
	const API_URL = getApiUrl('user');
	const ret = await axios.post(API_URL + `/user/${_id}`, data, JWT());
	return ret.data;
}

const CreateIuBendaConsent = async (data) => {
	const headers = { ApiKey: '4paJj3dzSvRYPlF7aGejmVzLtzHqKLi4' };
	await axios.post('https://consent.iubenda.com/consent/', data, { headers });
}

const RequestPasswordEmailLambda = (data) => async (dispatch) => {
	const API_URL = getApiUrl('user');

	const ret = await axios.post(API_URL + '/requrestpasswordemail', data, { headers: RayLinkHeaderNoAuth });
	return ret.data;
}

const VerifyPasswordEmailLambda = (data) => async (dispatch) => {
	const API_URL = getApiUrl('user');
	const ret = await axios.post(API_URL + '/verifypasswordemail', data, { headers: RayLinkHeaderNoAuth });
	return ret.data;
}

export const AuthAction = {
	Login,
	Logout,
	CreateIuBendaConsent,
	RequestPasswordEmailLambda,
	VerifyPasswordEmailLambda,
	UserUpdate,
	UpdatePassword,
	ModifyPassword,
	ModifyPhone
};
