const initialState = { 
	alert : {
		show : false,
		msg : "",
		type : ""
	}
};

export const AlertConstant = {
	ERROR : "ALERT_ERROR",
	NOTICE : "ALERT_NOTICE",
};

export const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
		case AlertConstant.NOTICE : 
			return {...state, alert : { show : action.show || false, msg : action.msg, type : "success" } };
		case AlertConstant.ERROR : 
			return {...state, alert : { show : action.show || false, msg : action.msg, type : "warning" } };
        default:
            return state
    }
}