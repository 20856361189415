import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { AppConfig } from "../config";
import { CURRENCY_STR } from "../constant/payment";
import { history } from "../history";
import { calcPeriodStr, getIsSubscribableKr, payableSQA, periodStr, setRtConfig } from "../lib/function";
import { UserAction, SubscriptionAction } from "../redux/action";
import { AppAction } from "../redux/action/AppAction";
import { SubscriptionConstant } from "../redux/reducer/SubscriptionReducer.js";

import PeriodStr from "./PeriodStr";

const BtnSubscription = ({ appInfo, subsFusion, GetAllSubscriptions }) => {
    const [products, setProducts] = useState([]);
    const [hasSub, setHasSub] = useState([]);
    const [ready, setReady] = useState(false);
    const { t } = useTranslation(['translation']);
    const { appCode } = useParams();
    const user = localStorage.getItem('user');
    const [visibleContents, setVisibleContents] = useState(false);
    const [realPrice, setRealPrice] = useState();
    const [currency, setCurrency] = useState("");
    const [periodSet, setPeriodSet] = useState({});
    const [trialPeriodSet, setTrialPeriodSet] = useState({});
    const [subs, setSubs] = useState([]);
    const [userSubscription, setUserSubscription] = useState(null);
    const includesTrial = useMemo(() => !userSubscription && products[0]?.prices?.trialperiod > 0, [userSubscription, products[0]?.prices?.trialperiod]);
    const [hasPayment, setHasPayment] = useState(false);

    useEffect(() => {
        setReady(false);
    }, []);

    useEffect(() => {
        (async () => {
            await setRtConfig();
            const _v = await payableSQA();
            setVisibleContents(_v);
        })();
    }, []);

    useEffect(() => {
        if (appCode === appInfo.appCode) {
            setProducts([]);
            setHasSub([]);
            getAppProducts();
        }
    }, [appInfo]);

    const checkUserProducts = useCallback(async (productId) => {
        let _userSubs = await UserAction.GetUserItems({ sk: `product:${productId}`, match: false });
        _userSubs = _userSubs.sort((a, b) => a.created > b.created ? -1 : 1);
        console.log("checkUserProducts : ===============", productId);
        setSubs(_userSubs);
        setUserSubscription(_userSubs[0]);
    }, []);

    useEffect(() => {
        if (!products || products.length === 0) {
            return;
        }
        checkUserProducts(products[0]._id)
    }, [products]);

    useEffect(() => {
        if (products.length > 0) {
            const _product = products[0];
            if (_product?.prices?.pg) {
                checkSub();
            } else {
                setReady(true);
            }
        } else {
            setReady(true);
        }
    }, [subs]);

    useEffect(() => {
        const buyRAYFusion = subsFusion.filter(x => x.lastrenewdata?.product?.appitems?.includes("App-RAYFusion") && !x.canceled && x.expired > new Date().getTime());
        setHasSub(buyRAYFusion);
    }, [subsFusion])

    const checkSub = async () => {
        if (products[0]?.appitems?.length === 0)
            return;
        const _product = products[0];
        const appKey = _product.appitems[0];

        if (appKey == "App-RAYFusion") {
            await GetAllSubscriptions();
        }

        if (appKey === appInfo.licenseId) {
            const filter = subs.filter(x => x.lastrenewdata?.product?.appitems?.includes(appKey) && !x.canceled);
            const _hasSub = filter.length > 0;
            setHasSub(filter);
            setRealPrice(_product.prices.realprice);
            setCurrency(_product.prices.punit);
            const _periodSet = calcPeriodStr(_product.subperiod);
            const _trialPeriodSet = _hasSub ? {} : calcPeriodStr(`${_product?.prices?.trialperiod}${_product?.prices?.trialperiodtype}`);
            setPeriodSet(_periodSet);
            setTrialPeriodSet(_trialPeriodSet);
        } else {
            setHasSub([]);
            setRealPrice();
            setCurrency("");
            setPeriodSet({});
            setTrialPeriodSet({});
        }
        setReady(true);
    }

    const getAppProducts = async () => {
        if (appInfo.licenseId && user) {
            const ret = await AppAction.GetAppProducts(appInfo.licenseId);
            console.log("getAppProducts : ===", ret);
            setProducts(ret?.filter(x => x.buytype === "sub" && !x.isdraft && x.appitems.includes(appInfo.licenseId)) || []);
            setHasPayment(true);
        } else {
            setProducts([]);
            setHasPayment(false);
        }
    }

    return <>
        {!hasPayment && <>
            {(appCode === "DigitalSmileDesignInAppExportMockUp" || appCode === "RAYFusion") && !user && <>
                <button
                    onClick={() => history.push(`/login/${appCode}`)}
                    className="btn btn-primary"
                    style={{
                        borderRadius: 400
                    }}
                >
                    {t("구독하기")}
                </button>
            </>}
        </>}
        {hasPayment && <>
            {user && visibleContents && !ready && <>
                <span className="text-muted" style={{ fontStyle: "italic" }}>{t("잠시만 기다려주세요.")}</span>
            </>}
            {user && visibleContents && ready && <>
                {products.length > 0 && <>
                    {<>
                        {hasSub.length === 0 && realPrice && <>
                            <button
                                onClick={() => history.push(`/pay/${appCode}`)}
                                className="btn btn-primary"
                                style={{
                                    borderRadius: 400
                                }}
                            >
                                <div className="d-flex align-items-center gap-1">
                                    <div className="d-flex align-items-center" style={{ gap: 3 }}>
                                        <div>
                                            {CURRENCY_STR[currency]?.default === "원" && <>
                                                {t("{{price}}원", {
                                                    price: realPrice?.toLocaleString(),
                                                    priceUnit: CURRENCY_STR[currency]?.default || "",
                                                })}
                                            </>}
                                            {CURRENCY_STR[currency]?.default !== "원" && <>
                                                {t("{{price}}{{priceUnit}}", {
                                                    price: realPrice?.toLocaleString(),
                                                    priceUnit: CURRENCY_STR[currency]?.default || "",
                                                })}
                                            </>}
                                        </div>
                                        {periodSet?.days && <>
                                            <div>/</div>
                                            <div>
                                                <PeriodStr periodSet={periodSet} />
                                            </div>
                                        </>}
                                    </div>
                                    <div className="fw-lighter">
                                        {includesTrial && <>
                                            {t("({{period}} 무료)", {
                                                period: periodStr({ periodSet: trialPeriodSet }),
                                            })}
                                        </>}
                                        {!includesTrial && <>
                                            {t("구독하기")}
                                        </>}
                                    </div>
                                </div>
                            </button>
                        </>}
                        {hasSub.length > 0 && <button
                            onClick={() => history.push("/subscriptions")}
                            className="btn btn-primary"
                            style={{
                                borderRadius: 400
                            }}
                        >
                            {t("구독 관리")}
                        </button>}
                    </>}
                </>}
            </>}
        </>}
    </>
};

const mapState = (state) => {
    const appInfo = state.AppReducer.appInfo;
    const subsFusion = state.SubscriptionReducer.subs;
    return { appInfo, subsFusion };
};

const mapDispatch = dispatch => ({
    GetAllSubscriptions: (_id) => dispatch(SubscriptionAction.GetAllSubscriptions(_id))
});

export default connect(mapState, mapDispatch)(BtnSubscription);
