import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import 'moment/min/locales';

const HeaderLang = () => {
    const { t, i18n } = useTranslation(['translation']);
    const [lang, setLang] = useState(i18n.language);
    const [showLang, setShowLang] = useState(false);
    const updateLang = (e) => {
        i18n.changeLanguage(e);
    }
    useEffect(() => {
        setLang(i18n.language);
        setShowLang(false);
        moment.locale(i18n.language);
    }, [i18n.language])
    return (
        <>
             <li className="nav-item dropdown language-select text-uppercase">
                <a className="nav-link dropdown-item dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setShowLang(!showLang)}>
                    <img className="flag" src={`/assets/img/flags/world.svg`} alt="" />{lang.toLowerCase()}
                </a>

                <ul className={`dropdown-menu${showLang ? ' show' : ''}`}>
                    <li className={`nav-item${lang === 'en' ? ' active' : ''}`}>
                        <a className="dropdown-item" href="#" onClick={() => updateLang('en')}>ENGLISH
                            {lang === 'en' && <img className="flag" src="/assets/img/flags/check.svg" alt=""></img>}
                        </a>
                    </li>

                    <li className={`nav-item${lang === 'ko' ? ' active' : ''}`}>
                        <a className="dropdown-item" href="#" onClick={() => updateLang('ko')}>한국어
                            {lang === 'ko' && <img className="flag" src="/assets/img/flags/check.svg" alt=""></img>}
                        </a>
                    </li>
                </ul>
            </li>
        </>
    )
    
}

export default HeaderLang;