import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../history';
import { useTranslation } from 'react-i18next';
import { AuthAction } from '../redux/action/AuthAction';
import { AuthConstant } from '../redux/reducer';
import { passwordRegex } from '../lib/regex';
import Loader from './Loader';
import { getApiUrl } from '../lib/function';
import axios from 'axios';

const errCode = {
    MISSING_PARAMS: '누락된 값이 있거나 값이 맞지 않습니다.',
    ACCESS_DENY: '정상적인 접근이 아닙니다.',
    PAST_PASSWORD: '이전에 사용했던 비밀번호는 다시 사용할 수 없습니다.',
}

const PasswordChange = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState({ name: "", email: "", password: "", passwordConfirm: "" });
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [t] = useTranslation(['translation']);
    const [days, setDays] = useState(0);
    const [isFirstLogin, setIsFirstLogin] = useState(false);

    useEffect(() => {
        init();
        if (window?.localStorage?.getItem("_isFirstLogin") === "Y") {
            setIsFirstLogin(true);
        }

    }, []);

    useEffect(() => {
        if (window.rayteamsconfig && window.rayteamsconfig.userPwSecurity) {
            setDays(window.rayteamsconfig.userPwSecurity.passwordChangeNotification || 0);
        } else {
            setDays(0);
        }
    }, []);

    // 20240909 주석처리 -> Layout.js hideFooterIncludesStrings 변수에 changepw 페이지 추가
    // useEffect(() => {
    //     document.getElementsByTagName('footer')[0].style.display = 'none';
    // }, []);

    const init = () => {
        if (!(localStorage.getItem('tmpUserEmail') && localStorage.getItem('tmpUserToken'))) {
            history.push('/login');
        }
    }

    const updatePasswordPass = async () => {
        setShowLoader(true);
        const API_URL = getApiUrl('user', localStorage.getItem('tmpUserRegion'));
        var data = {
            type: 'update',
            data: {
                'needpwupdate': moment().add(window.rayteamsconfig?.userPwSecurity?.nextChangeNotificationPeriod, 'days').valueOf()
            },
        };
        try {
            await axios.post(API_URL + '/user/' + localStorage.getItem('tmpUserId'), data, {
                headers: {
                    token: localStorage.getItem('tmpUserToken'),
                    'x-ray-device': 'WEB'
                }
            });
            setShowLoader(false);
            alert(t("비밀번호 변경이 연기되었습니다."));
            history.push('/');
        } catch (error) {
            console.error("Error updating password pass:", error);
            setShowLoader(false);
            alert(t("오류가 발생했습니다."));
        }
    }

    const updatePassword = async () => {
        setErrMsg('');
        setShowLoader(true);
        setLoading(true);

        if (data.password !== data.passwordConfirm) {
            setErrMsg(t("패스워드와 패스워드확인이 일치하지 않습니다."));
            setShowLoader(false);
            setLoading(false);
            return;
        }

        if (!passwordRegex.test(data.password)) {
            setErrMsg(t('비밀번호는 최소 8자리, 대문자, 소문자, 숫자, 특수문자 각각 1개 이상 포함해야 합니다.'));
            setShowLoader(false);
            setLoading(false);
            return false;
        }

        const em = localStorage.getItem('tmpUserEmail');
        try {
            const ret = await AuthAction.UpdatePassword({
                "email": em,
                "password": data.password,
                "reusePasswordLimit": window.rayteamsconfig?.userPwSecurity?.reusePasswordLimit,
                "reusePassword": window.rayteamsconfig?.userPwSecurity?.reusePassword
            }, localStorage.getItem('tmpUserRegion'));
            setLoading(false);
            setShowLoader(false);
            if (ret.status === 'success') {
                alert(t("비밀번호 변경이 완료되었습니다."));
                localStorage.removeItem('tmpUserId');
                localStorage.removeItem('tmpUserRegion');
                localStorage.removeItem('tmpUserEmail');
                localStorage.removeItem('tmpUserToken');
                history.push('/login');
            } else {
                const failMessage = errCode[ret.error] || ret.error
                setErrMsg(t(failMessage));
            }
        } catch (error) {
            console.error("Error updating password:", error);
            alert(t("오류가 발생했습니다."));
            setShowLoader(false);
            setLoading(false);
        }
    };
    
    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin" style={{ zIndex: 5, position: 'relative' }}>
                    <div className="row gx-0 gy-8 align-items-center">
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                />
                            </a>
                            <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}
                            >
                                <span className="underline-2 violet">{t("안전한 서비스 이용 및 소중한 개인정보보호를 위해")}<br /> {t("비밀번호를 주기적으로 변경해 주세요.")}</span>
                            </p>
                        </div>
                        <div className="col-lg-5 offset-lg-1 signup">
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark">

                                    <h2 className="mb-2 text-start">{t("비밀번호를 변경해 주세요")}</h2>
                                    <p className='text-dark mb-4 text-start lh-sm'>
                                        {t("{{days}}일 이상 동일한 비밀번호를 사용하고 계시네요.", { days })}
                                        <br />
                                        {t("소중한 개인정보보호를 위해 비밀번호를 변경해 주세요.")}
                                    </p>
                                    <div className="form-floating password-field mb-2">
                                        <input type={showPassword ? 'text' : 'password'} className="form-control" placeholder="Password" onChange={(e) => setData({ ...data, password: e.target.value })} />
                                        <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                        <label>{t('비밀번호')} *</label>
                                    </div>
                                    <div className="form-floating password-field mb-6">
                                        <input type={showPasswordConfirm ? 'text' : 'password'} className="form-control" placeholder="Password Confirm" onChange={(e) => setData({ ...data, passwordConfirm: e.target.value })} />
                                        <span className="password-toggle" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}><i className={`uil uil-eye${showPasswordConfirm ? '-slash' : ''}`}></i></span>
                                        <label>{t('비밀번호 확인')} *</label>
                                    </div>

                                    {errMsg && <p className="text-danger mt-n4 lh-sm fs-14" style={{ textAlign: 'left' }}>{errMsg}</p>}

                                    <div className="row">
                                        <div className="col-12 col-md-2 order-1">
                                            <a className="btn btn-muted rounded-pill btn-login w-100" onClick={() => history.push('/login')}>
                                                {t('취소')}
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 order-2">
                                            {window.rayteamsconfig?.userPwSecurity?.nextChangeNotificationPeriod && <a className="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => updatePasswordPass()}>
                                                {window.rayteamsconfig?.userPwSecurity?.nextChangeNotificationPeriod + t('일 뒤에 변경')}
                                            </a>}
                                        </div>
                                        <div className="col-12 col-md-4 order-2">
                                            <a className="btn btn-primary rounded-pill btn-login w-100" onClick={() => updatePassword()}>{t('확인')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </div>
    )
}

const mapState = (state) => {
    const loginresult = state.AuthReducer.loginresult;
    const auth = state.AuthReducer.user;
    const users = state.UserReducer.items;
    const config = state.ProgramReducer.config;
    return { auth, users, config, loginresult };
};

const mapDispatch = dispatch => ({
    UpdateUser: (info) => dispatch(UserAction.Update(info)),
    UserUpdate: (info, toast) => dispatch(UserAction.UpdateUser(info, toast)),
});


export default connect(mapState, mapDispatch)(PasswordChange);
