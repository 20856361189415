let site = "";
if (window.location.hostname === 'clinic-test.raysmiler.co.kr' || window.location.hostname === 'clinic.raysmiler.co.kr' || window.location.hostname === 'c.raysmiler.co.kr') {
	site = "clinic"
} else if (window.location.hostname === 'admin-test.raysmiler.co.kr' || window.location.hostname === 'admin.raysmiler.co.kr' || window.location.hostname === 'a.raysmiler.co.kr') {
	site = "admin"
} else if (window.location.hostname === 'dealer-test.raysmiler.co.kr' || window.location.hostname === 'dealer.raysmiler.co.kr' || window.location.hostname === 'd.raysmiler.co.kr') {
	site = "dealer"
} else if (window.location.hostname === 'lab-test.raysmiler.co.kr' || window.location.hostname === 'lab.raysmiler.co.kr' || window.location.hostname === 'l.raysmiler.co.kr') {
	site = "lab"
} else if (window.location.hostname.includes('192.168') || window.location.hostname.includes('localhost')) {
	site = "raylink"
}

const initialState = {
	site,
	visibleFooter: true,
	notifyItems: []
};

export const ProgramReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProgramConstant.VISIBLE_FOOTER:
			return {
				...state,
				visibleFooter: action.bool,
			}
		case ProgramConstant.GET_NOTIFY_ITEMS:
			return {
				...state, 
				notifyItems: action.items,
			}
		default:
			return state;
	}
}

export const ProgramConstant = {
	API_REQUEST: "API_REQUEST",
	API_RESPONSE: "API_RESPONSE",
	PAGE_INIT: "PAGE_INIT",
	UPDATERAYLINKINFO: "UPDATERAYLINKINFO",
	MODAL_SHOW: "MODAL_SHOW",
	MODAL_CLOSE: "MODAL_CLOSE",
	UPLOADMODAL_SHOW: "UPLOADMODAL_SHOW",
	UPLOADMODAL_CLOSE: "UPLOADMODAL_CLOSE",
	UPLOADMODAL_DONE: "UPLOADMODAL_DONE",
	UPLOADMODAL_CLEAR: "UPLOADMODAL_CLEAR",
	CONNECT_DIRECT: "UPLOADMODAL_CONNECT_DIRECT",
	GET_DIR: "GET_DIR",

	APP_SETTING_UPDATE: "APP_SETTING_UPDATE",
	APP_SETTING_DELETE: "APP_SETTING_DELETE",

	SOCKET_COMMAND: "SOCKET_COMMAND",
	SOCKET_SEND: "SOCKET_SEND",

	VISIBLE_FOOTER: "PROGRAM_SET_VISIBLE_FOOTER",
	GET_NOTIFY_ITEMS: "GET_NOTIFY_ITEMS",
};