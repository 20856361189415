import { useEffect } from "react";
import { history } from "../history";
import { useTranslation } from 'react-i18next';

const SignUpSelectType = ({ officeData, setOfficeData }) => {
    const [t] = useTranslation(['translation']);

    useEffect(() => {
        if(document.getElementsByTagName('footer').length > 0)
            document.getElementsByTagName('footer')[0].style.display = 'none';
    }, []);

    return (
        <>
            <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
                <section className="wrapper text-white">
                    <div className="container pt-12 pb-10 pt-md-21 pb-md-18 signin signup-1" style={{ zIndex: 5, position: 'relative' }}>
                        <div className="row gx-0 gy-8 align-items-center">
                            <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                                <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                    <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                        style={{
                                            animationName: 'slideInDown',
                                            animationDuration: '700ms',
                                            animationTimingFunction: 'ease',
                                            animationDelay: '600ms',
                                            animationDirection: 'normal',
                                            animationFillMode: 'both'
                                        }}
                                    />
                                </a>
                                <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '900ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                >
                                    Log in or <span className="underline-2 violet">sign up</span> for RAYTeams.<br />One account for RAYTeams Cloud and client.
                                </p>
                            </div>
                            <div className="col-lg-6 signup">
                                <div className="card">
                                    <div className="card-body p-11 text-center text-dark pt-9">
                                        <h2 className="mb-2 text-start">{t('회원가입')}</h2>
                                        <p className="text-start text-primary mb-0">{t('아래 항목에서 귀하가 소속한 유형을 선택 후 정보를 입력해 주세요.')}</p>
                                        <p className="text-start">{t('이미 기관이 가입되어 있다면 해당 기관의 매니저 계정의 유저가 로그인하여 당신을 초대해야 합니다.')}</p>
                                        <div className="text-start mb-3 signup-select-type">
                                            <div className="row mb-3 ms-0">
                                                <a href="#" onClick={() => setOfficeData({ ...officeData, grouptype: ['clinic'] })}>
                                                    <div className="d-flex flex-row px-0 py-3">
                                                        <div>
                                                            <div className="btn-circle me-4"> <img src="/assets/img/signup-clinic.svg" /> </div>
                                                        </div>
                                                        <div>
                                                            <h4 className="mb-0">{t('치과')}</h4>
                                                            <p className="mb-1 text-dark">{t('치과의사, 치과위생사 및 직원')}</p>
                                                            <span>{t('치과 계정으로 가입합니다.')}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#" onClick={() => setOfficeData({ ...officeData, grouptype: ['lab'] })}>
                                                    <div className="d-flex flex-row px-0 py-3">
                                                        <div>
                                                            <div className="btn-circle me-4"> <img src="/assets/img/signup-lab.svg" /> </div>
                                                        </div>
                                                        <div>
                                                            <h4 className="mb-0">{t("기공소")}</h4>
                                                            <p className="mb-1 text-dark">{t('기공사 및 직원')}</p>
                                                            <span>{t('기공소 계정으로 가입합니다.')}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="#" onClick={() => setOfficeData({ ...officeData, grouptype: ['other'] })} style={{display:"none"}}>
                                                    <div className="d-flex flex-row px-0 py-3">
                                                        <div>
                                                            <div className="btn-circle me-4"> <img src="/assets/img/signup-other.svg" /> </div>
                                                        </div>
                                                        <div>
                                                            <h4 className="mb-0">{t('기타')}</h4>
                                                            <p className="mb-1 text-dark">{t('기타 기관 등')}</p>
                                                            <span>{t('기타 계정으로 가입합니다.')}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <p className="mb-3 text-start mb-n2">{t('이미 계정을 가지고 계신가요?')} <a href="#" className="hover" onClick={(e) => { e.preventDefault(); history.push('/login') }}>{t('로그인으로 이동')}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default SignUpSelectType;