import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Header from "./Header"
import AppHeader from "./AppHeader";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { BoardAction } from '../redux/action/BoardAction';

const BoardList = () => {

    const { t, i18n } = useTranslation(['translation']);
    const { bid } = useParams();
    const [ list, setList ] = useState([]);

    useEffect(() => {
        getList()
    }, [i18n.language, bid]);

    const getList = async () => {
        const data = await BoardAction.GetList(bid);
        setList(data.filter(x => x.sk.indexOf(":" + i18n.language) > -1).sort((a, b) => b.updated - a.updated));
    }

    const extractInnerHTML = (htmlString, splitStr = " ") => {
        if (!htmlString || typeof htmlString !== "string")
            return htmlString;
        const regex = /(<([^>]+)>)/ig;
        return htmlString.replace(regex, splitStr);
    };

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: 'url("/assets/img/bg1.jpg")' }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={'/assets/img/app-icon/img_rayteams.png'} alt={'Notice'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">RAYTeams Cloud</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('Notice')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container manual">
                <div className="col-xl-12 order-1 pt-md-8 pb-10 board-list">
                    <section className="wrapper">
                        <h2 className="mb-4">Announcements</h2>
                        <div className="mt-0 mt-md-5">
                            <div className="notice-list">
                                <ul>
                                    {list.map(x => <li>
                                        <p className="date">{moment(x.created).format("LL")}</p>
                                        <h5><Link to={"/board/view/" + x._id}>{x.title}</Link></h5>
                                        <p><Link to={"/board/view/" + x._id}>{extractInnerHTML(x.content).substring(0, 100)}</Link></p>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default BoardList;
