import { useTranslation } from 'react-i18next';

const RAYModal = ({ show, msg, btnmsg, callback, btncomp, feedback }) => {
    const [t, i18n] = useTranslation(['translation']);

    return <>
		<div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: show ? 'block' : "none" }}>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-body">
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => callback(false)}></button>
						<div className="">
							{msg}
						</div>
						{feedback && feedback}
						<div className="mt-6">
							{btncomp && btncomp}
							{!btncomp && <p className="text-center pb-0 mb-0">
								<a className="btn btn-icon btn-icon-start rounded-pill btn-lg" onClick={() => callback(true)}>
									<i className="uil uil-check"></i> {btnmsg}
								</a>
							</p>}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop fade show" style={{ display: show ? 'block' : "none" }}></div>
	</>
}
export default RAYModal;