
const Loader = ({ isShow }) => {
    return (
        isShow &&
        <>
            <div className="modal fade show" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content text-center">
                        <div className="modal-body">
                            <div className="loadingio-spinner-dual-ball-sw6sftzohqk"><div className="ldio-rhxguy7x4r">
                                <div></div><div></div><div></div>
                            </div></div>
                            <div className="text-center text-primary">
                                <h5 className="mb-0">Loading...</h5><p className="mb-0">Please wait a moment.</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas-backdrop fade show"></div>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </>
    )
}

export default Loader;