import { history } from "../history";
import { useTranslation } from 'react-i18next';

const SignUpComplete = () => {
    const [t] = useTranslation(['translation']);
    
    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section class="wrapper text-white">
                <div class="container pt-12 pb-10 pt-md-21 pb-md-18 signin signup-3" style={{ zIndex: '5', position: 'relative' }}>
                    <div class="row gx-0 gy-8">
                        <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start pt-md-21 signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="/">
                                <img src="./assets/img/bi_rayteams_light.svg" class="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }} />
                            </a>
                            <p class="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                                }}>
                                Log in or <span class="underline-2 violet">sign up</span> for RAYTeams.<br />
                                One account for RAYTeams Cloud and client.
                            </p>
                        </div>
                        <div class="col-lg-6 signup">
                            <div class="card">
                                <div class="card-body p-11 text-dark text-center keep-all">
                                    <h2 class="mb-4 text-center text-primary">{t('회원가입이 완료되었습니다.')}</h2>
                                    <p>{t('RAYTeams Cloud에 가입해 주셔서 감사합니다.')}<br />{t('보다 좋은 서비스를 보여 드리기 위해 최선을 다하겠습니다.')}</p>
                                    <p class="text-center mb-8"><img src="./assets/img/img_signup_ok.png" /></p>
                                    <form class="text-start mb-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <a href="#" class="btn btn-primary rounded-pill btn-login w-100 mb-3"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push('/login');
                                                    }}>
                                                    {t('로그인')}
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="mb-3 text-start mb-n2">
                                        {t('로그인 없이 홈으로 이동할까요?')} <a href="#" class="hover" onClick={(e) => { e.preventDefault(); history.push('/'); }}>{t('메인페이지')}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SignUpComplete;