import axios from 'axios';
import { JWT } from '../config';
import { getApiManagerUrl } from '../lib/function';
import React, { useEffect, useState } from 'react';
import { history } from '../history';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AuthAction } from '../redux/action';
import { emailRegex, passwordRegex } from '../lib/regex';
import { useParams } from 'react-router-dom';
import Loader from './Loader';

const AutoSub = ({ user, Login, RequestPasswordEmailLambda, VerifyPasswordEmailLambda }) => {
    const [t] = useTranslation(['translation']);
    const [data, setData] = useState({ email: '', password: '', code: '', errMsg: '', status: '' });    
    const [showLoader, setShowLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const { _appId } = useParams();
    const { _lcType } = useParams();
    useEffect(() => {
        document.getElementsByTagName('footer')[0].style.display = 'none';
    }, []);

    const checkEmail = async () => {
        if (!data.email) {
            setData({ ...data, errMsg: t('이메일을 입력해주세요.') });
			return;
        }

        if (!emailRegex.test(data.email)) {
            setData({ ...data, errMsg: t('올바르지 않은 형식의 이메일입니다.') });
			return;
		}

        setShowLoader(true);
        const ret = await RequestPasswordEmailLambda({ email: data.email, appName: 'RAYTeams' });
		setShowLoader(false);

        if (ret.status === 'success') {
            setData({ ...data, errMsg: '', status: 'EMAIL_CHECKED' });
        } else {
            switch (ret?.error) {
                case 'NOT_FIND_USER':
                    setData({ ...data, errMsg: t('존재하지 않는 이메일입니다.') });
                    break;
                case 'ACCESSDENY':
                    setData({ ...data, errMsg: t('오류가 발생하였습니다.') });
                    break;
                case 'FAIL_SAND_MAIL':
                    setData({ ...data, errMsg: t('이메일 발송에 실패하였습니다.') });
                    break;
                default:
                    setData({ ...data, errMsg: t('오류가 발생하였습니다.') }); 
                    break;
            }
        }
    }

    const loginHandler = async () => {
        if (!data.email) {
            setData({ ...data, errMsg: t('이메일을 입력해주세요.') });
            return;
        }
		if (!emailRegex.test(data.email)) {
            setData({ ...data, errMsg: t('올바르지 않은 형식의 이메일입니다.') });
			return;
		}

        setShowLoader(true);
        const ret = await Login(data);
        if(ret.status !== 'success')
            ret.error && setData({ ...data, errMsg: ret.error });

        if(ret.data?.user) {
            const params = 
            {
                user: ret.data?.user,
                appId: _appId,
                licenseType: _lcType
            };

            const data = { data: params };
            const API_URL = getApiManagerUrl('license');
            const retautosub = await axios.post(API_URL + `/license/autosubscribebyuser`, data, JWT());
            console.log("autosub", retautosub?.data?.data);
            if (retautosub?.data?.data?.check) {
                setData({ ...data, status: 'VERIFIED', errMsg: t(retautosub?.data?.data?.reason) });
            } else {
                setData({ ...data, status: "VERIFIED", errMsg: t(retautosub?.data?.data?.reason) })
            }
            

        } else {
            setData({ ...data, errMsg: t('일치하는 사용자 정보가 없습니다.') });
        }
        setShowLoader(false);
    }

    const handleChange = async (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <div className="content-wrapper rt-site image-wrapper bg-image bg-overlay bg-overlay-400 bg-content" data-image-src="/assets/img/bg_signin.jpg" style={{ backgroundImage: 'url(/assets/img/bg_signin.jpg)', height: '100%' }}>
            <section className="wrapper text-white">
                <div className="container pt-12 pb-10 pt-md-21 pb-md-18" style={{ zIndex: 5, position: 'relative', height: "300px" }}>
                    <div className="row gx-0 gy-8 align-items-center">
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start signin-logo" data-cues="slideInDown" data-group="page-title" data-delay="600" data-disabled="true">
                            <a href="#" onClick={(e) => { e.preventDefault(); history.push('/') }}>
                                <img src="/assets/img/bi_rayteams_light.svg" className="mb-5" alt="RAYTeams" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                    style={{
                                        animationName: 'slideInDown',
                                        animationDuration: '700ms',
                                        animationTimingFunction: 'ease',
                                        animationDelay: '600ms',
                                        animationDirection: 'normal',
                                        animationFillMode: 'both'
                                    }}
                                />
                            </a>
                            <p className="lead fs-lg lh-sm" data-cue="slideInDown" data-group="page-title" data-delay="600" data-show="true"
                                style={{
                                    animationName: 'slideInDown',
                                    animationDuration: '700ms',
                                    animationTimingFunction: 'ease',
                                    animationDelay: '900ms',
                                    animationDirection: 'normal',
                                    animationFillMode: 'both'
                            }}>
                                {/* <span className="underline-2 violet">Log in</span> or sign up for RAYTeams.<br />One account for RAYTeams Cloud and client. */}
                            </p>
                        </div>
                        <div className="col-lg-5 offset-lg-1 signup">
                            <div className="card">
                                <div className="card-body p-11 text-center text-dark">
                                    {data.status === '' && <>
                                        <h2 className="mb-3 text-start">{t('구독 연장')}</h2>
                                        <p className="mb-2 text-start text-primary">{t('구독을 연장 하려면 아래 인증을 해주세요.')}</p>
                                        <div className="form-floating mb-4">
                                            <input type="email" className="form-control" placeholder="Email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} onKeyDown={(e) => e.key === 'Enter' && checkEmail()} />
                                            <label htmlFor="loginEmail">{t('이메일')}</label>
                                        </div>
                                        <div className="form-floating password-field mb-4">
                                            <input
                                                type={!showPassword ? "password" : "text"}
                                                name="password"
                                                className="form-control"
                                                placeholder={t('비밀번호')}
                                                id="loginPassword"
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === 'Enter' && loginHandler()}
                                            />
                                            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}><i className={`uil uil-eye${showPassword ? '-slash' : ''}`}></i></span>
                                            <label htmlFor="loginPassword">{t('비밀번호')}</label>
                                        </div>
                                        {data.errMsg && <p className="text-danger mt-n4" style={{textAlign:'left'}}>{data.errMsg}</p>}
                                        <a className="btn btn-primary rounded-pill btn-login w-100 mb-2" onClick={loginHandler}>{t('확인')}</a>
                                    </>}
                                    {data.status === 'VERIFIED' && <>
                                        <h2 className="mb-8 text-center text-primary">{data.errMsg}</h2>
                                        <div className="row">
                                            <div className="col-12 col-md-12 order-1 mt-2 mt-lg-0 mt-md-0">
                                                <a className="btn btn-soft-primary rounded-pill btn-login w-100" onClick={() => history.push('/')}>{t('메인페이지')}</a>
                                            </div>
                                            
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </div>
    )
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
	const loginresult = state.AuthReducer.loginresult;
	return { user, loginresult };
};

const mapDispatch = dispatch => ({
	Login: (data) => dispatch(AuthAction.Login(data)),
})

export default connect(mapState, mapDispatch)(AutoSub);
