const initialState = { 
	items : [],
	item : {},	
};

export const GroupConstant = {
	CREATE : "GROUP_CREATE",
	CREATED : "GROUP_CREATED",
	UPDATE : "GROUP_UPDATE",
	UPDATED : "GROUP_UPDATED",
	GETALL : "GROUP_GETALL",
	GET : "GROUP_GET",
	STATUSCLEAR : "GROUP_STATUSCLEAR",
	SET_RELATED_GROUPS: "GROUP_SET_RELATED_GROUPS",
	ADD_RELATED_GROUP: "GROUP_ADD_RELATED_GROUP",
	SUB_RELATED_GROUP: "GROUP_SUB_RELATED_GROUP",
	ADD_CALLED_GROUP_ID: "GROUP_ADD_CALLED_GROUP_ID",
	SET_MARKERS: "GROUP_SER_MARKERS",
	SET_LICENSE: "GROUP_SET_LICENSE",
};

export const GroupReducer = (state = initialState, action) => {
    switch (action.type) {
	
        default:
            return state;
    }
}