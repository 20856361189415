import { useEffect, useState } from "react";
import Header from "./Header"
import AWS from 'aws-sdk';
import { AppConfig } from "../config";
import Loader from "./Loader";


const ContactUs = () => {
    const [sent, setSent] = useState(false);
    const [data, setData] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showError, setShowError] = useState(false);

    const sendMail = () => {
        if (!data.subject || !data.email || !data.message)
            setShowError(true);
        else {
            setShowError(false);
            setShowLoader(true);
            AWS.config.update({
                region: 'ap-northeast-2',
                secretAccessKey: AppConfig.MAIL_SEND_SECRET_KEY,
                accessKeyId: AppConfig.MAIL_SEND_ACCESS_KEY
            });

            const params = {
                Destination: { ToAddresses: ['support@rayteams.com'] },                
                Message: {
                    Body: { Text: { Charset: "UTF-8", Data: data.message + "\n=============================================================\n from " + data.email } },
                    Subject: { Charset: 'UTF-8', Data: '[RAYTeams Homepage - Online inquiries] ' + data.subject }
                },
                Source: 'support@rayteams.com'
            };

            const sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();

            sendPromise
                .then((data) => {
                    setSent(true);
                    setData({ subject: '', email: '', message: '' });
                })
                .catch((err) => console.log(err))
                .finally(() => setShowLoader(false));
        }
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="./assets/img/bg1.jpg" style={{ backgroundImage: "url('./assets/img/bg1.jpg')" }}>
                <div className="sub-header-none"></div>
            </section>
            <section className="wrapper bg-soft-violet">
                <div className="container pt-6 pt-md-16 pb-14">
                    <div className="row gy-10 gx-lg-8 gx-xl-12 align-items-center">
                        <div className="col-lg-7 position-relative contact-visual">
                            <div className="shape bg-dot primary rellax w-18 h-18" data-rellax-speed="1"
                                style={{
                                    top: '0',
                                    left: '-1.4rem',
                                    zIndex: '0',
                                    transform: 'translate3d(0px, -40px, 0px)'
                                }}></div>
                            <div className="row gx-md-5 gy-5">
                                <div className="col-md-6">
                                    <figure className="rounded mt-md-10 position-relative"><img src="./assets/img/img_contact1.jpg" srcSet="./assets/img/img_contact1.jpg 2x" alt="" /></figure>
                                </div>
                                <div className="col-md-6">
                                    <div className="row gx-md-5 gy-5">
                                        <div className="col-md-12 order-md-2">
                                            <figure className="rounded"><img src="./assets/img/img_contact2.jpg" srcSet="./assets/img/img_contact2.jpg 2x" alt="" /></figure>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="card bg-pale-primary text-center counter-wrapper">
                                                <div className="card-body py-11">
                                                    <h3 className="counter text-nowrap" style={{ visibility: 'visible' }}>13</h3>
                                                    <p className="mb-0">Regions worldwide</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 contact-info">
                            <h2 className="display-4 mb-8">Do you need any assistance? Please <span className="underline-3 style-3 violet text-violet">contact us</span>.</h2>
                            <h3 className="mb-5">Ray Co., Ltd.</h3>
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                                </div>
                                <div>
                                    <h5 className="mb-1">Address</h5>
                                    <address>12F, 221, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Korea 13494</address>
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
                                </div>
                                <div>
                                    <h5 className="mb-1">Phone</h5>
                                    <p>+82-31-605-1000</p>
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-envelope"></i> </div>
                                </div>
                                <div>
                                    <h5 className="mb-1">E-mail</h5>
                                    <p className="mb-0"><a href="mailto:support@rayteams.com" className="link-body">support@rayteams.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper inquiry">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row" id="inquiry">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <h2 className="display-2 mb-2 text-center text-light">Online inquiries</h2>
                            <p className="lead text-center mb-8 text-light opacity-75">Please contact us for inquiries on RAYTeams.</p>
                            {
                                sent &&
                                <div className="messages">
                                    <div className="alert alert-primary alert-icon alert-dismissible fade show">
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSent(false)}></button><i className="uil uil-check-circle"></i> Thank you for your message. It has been sent.
                                    </div>
                                </div>
                            }
                            {
                                showError &&
                                <div className="messages">
                                    <div className="alert alert-danger alert-icon alert-dismissible fade show">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowError(false)}></button><i className="uil uil-times-circle"></i> You must enter all fields.
                                    </div>
                                </div>
                            }
                            <div className="row gx-4">
                                <div className="col-md-8">
                                    <div className="form-floating mb-4">
                                        <input id="form_subject" type="text" name="subject" className="form-control" placeholder="Subject" required onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}  value={data.subject} />
                                        <label htmlFor="form_subject">Subject *</label>
                                        <div className="valid-feedback"> Looks good! </div>
                                        <div className="invalid-feedback"> Please enter subject. </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating mb-4">
                                        <input id="form_email" type="email" name="email" className="form-control" placeholder="email" required onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} value={data.email} />
                                        <label htmlFor="form_email">Email *</label>
                                        <div className="valid-feedback"> Looks good! </div>
                                        <div className="invalid-feedback"> Please provide a valid email address. </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-4">
                                        <textarea id="form_message" name="message" className="form-control" placeholder="Your message" style={{ height: '250px' }} required onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} value={data.message}></textarea>
                                        <label htmlFor="form_message">Message *</label>
                                        <div className="valid-feedback"> Looks good! </div>
                                        <div className="invalid-feedback"> Please enter your messsage. </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <input type="button" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message" onClick={()=> sendMail()} />
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </>
    )
}

export default ContactUs;