import { useEffect } from 'react';
import { AppAction } from '../redux/action';
import Header from './Header';
import { connect } from "react-redux";
import { moveTo } from '../lib/function';
import { history } from '../history';

const Apps = ({appList, GetAppList}) => {

    useEffect(() => {
        GetAppList('');
    }, []);

    const getCategoryName = (x) => {
        if (x.category === 'app') return 'Application';
        if (x.category === 'converter') return 'Converter';
        if (x.category === 'util') return 'Utility';
        return x.category;
    }

    return (
        <>
            <Header />
            <section className="wrapper apps-visual mt-n2 mt-lg-n1">
                <div className="container">
                    <div className="card image-wrapper bg-full bg-image bg-overlay mt-2 shadow-lg">
                        <div className="py-7">
                            <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center text-center text-lg-start">
                                <div className="col-lg-7">
                                    <h1 className="display-1 mb-4 text-white">Apps</h1>
                                    <p className="fs-23 lh-sm pe-xxl-15 text-white fst-normal">Discover and install apps for RAYTeams Cloud.<br />And check out the combination of apps you need.</p>
                                </div>
                                <div className="col-lg-5 p-0 text-center">
                                    <img className="img-fluid" src="/assets/img/app-list-visual.png" srcset="/assets/img/app-list-visual@2x.png 2x" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-apps apps-list">
                <div className="container py-12">
                    <h2 className="display-6 mb-8 text-center">Download RAYTeams' popular apps!</h2>
                    <div className="row gy-6">
                        {appList.map((x, idx) => <div className="col-md-6 col-lg-4" key={`apps_${idx}`}>
                            <a href="#" className="card shadow-none lift h-100" onClick={(e) => moveTo(history, e, `/${x.appCode}`)}>
                                <div className="p-5 pb-0 d-flex flex-row">
                                    <div className="me-auto">
                                        <h3 className="mb-1">{x.appName}</h3>
                                        <span className="badge bg-converter rounded py-1 mb-2">{getCategoryName(x)}</span>
                                    </div>
                                    <div className="ms-4">
                                        <img className="rounded-2 w-11" src={x.app_icon} alt="logcenter" />
                                    </div>
                                </div>
                                <p className="text-body p-5 pb-0 pt-2" dangerouslySetInnerHTML={{ __html: x.desc }}></p>
                                <p className="mb-0 text-body px-5 pt-0 pb-4 text-primary">Read more <i className="me-2 uil-arrow-right fs-lg"></i></p>
                            </a>
                        </div>)}
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container">
                    <div className="row py-14">
                        <div className="col-12">
                            <h2 className="display-2 mb-3 text-center" style={{"color" : "#6045C1"}}>Usage and workflow</h2>
                            <p className="text-center fs-lg mb-6 text-secondary lh-sm">Check out the RAYTeams usage and workflow video below.<br />More details can be found in the video below.</p>
                            <div className="row">
                                <div className="item-inner col">
                                    <iframe width="100%" height="225" src="https://www.youtube.com/watch?v=FyzqtWplV4c" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                                <div className="item-inner col">
                                    <iframe width="100%" height="225" src="https://www.youtube.com/watch?v=Z0UPW2lmQ5U" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                                <div className="item-inner col">
                                    <iframe width="100%" height="225" src="https://www.youtube.com/watch?v=z76Hwj8LEvc" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg_combination">
                <div className="wrapper bg-image text-white bg-full" data-image-src="./assets/img/bg_orthodontic.png" style={{"backgroundImage" : "url(/assets/img/bg_orthodontic.png)"}}>
                    <div className="container py-14 py-md-17">
                        <div className="col-lg-6">
                            <div className="btn btn-md btn-white rounded-pill mb-4 p-1 cursor-default">
                                <img className="rounded-circle w-9 me-1" src="/assets/img/app-icon/apps-RAYDENT-Converter.svg" alt="apps-RAYDENT-Converter" />
                                <img className="rounded-circle w-9 me-1" src="/assets/img/app-icon/apps-cad-converter.svg" alt="apps-cad-converter" />
                                <img className="rounded-circle w-9" src="/assets/img/app-icon/apps-exocad.svg" alt="apps-exocad" />
                            </div>
                            <h2 className="display-4 mb-5 text-white">Orthodontic Apps for Dentists!</h2>
                            <p>
                                Run SmartOrtho on RAYTeams, proceed and complete Setup on SmartOrthos, and export rayteams. RAYTEAMS generates SmartCheck related files upon request and uploads them to the cloud when the task is complete.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-apps apps-list">
                <div className="container py-12">
                    <div className="row gy-6">
                        {appList.filter(x => x.appCode === "rayface").map((x, idx) => <div className="col-md-6 col-lg-4" key={`apps_${idx}`}>
                            <a href="#" className="card shadow-none lift h-100" onClick={(e) => moveTo(history, e, `/${x.appCode}`)}>
                                <img className="card-img-top" src="/assets/img/img_thumb_rf.png" alt="" />
                                <div className="p-5 pb-0 d-flex flex-row">
                                    <div className="me-auto">
                                        <h3 className="mb-1">{x.appName}</h3>
                                        <span className="badge bg-converter rounded py-1 mb-2">{getCategoryName(x)}</span>
                                    </div>
                                    <div className="ms-4">
                                        <img className="rounded-2 w-11" src={x.app_icon} alt="logcenter" />
                                    </div>
                                </div>
                                <p className="text-body p-5 pb-0 pt-2" dangerouslySetInnerHTML={{ __html: x.desc }}></p>
                                <p className="mb-0 text-body px-5 pt-0 pb-4 text-primary">Read more <i className="me-2 uil-arrow-right fs-lg"></i></p>
                            </a>
                        </div>)}
                        {appList.filter(x => x.appCode === "SmartOrtho").map((x, idx) => <div className="col-md-6 col-lg-4" key={`apps_${idx}`}>
                            <a href="#" className="card shadow-none lift h-100" onClick={(e) => moveTo(history, e, `/${x.appCode}`)}>
                                <img className="card-img-top" src="/assets/img/img_thumb_rf.png" alt="" />
                                <div className="p-5 pb-0 d-flex flex-row">
                                    <div className="me-auto">
                                        <h3 className="mb-1">{x.appName}</h3>
                                        <span className="badge bg-converter rounded py-1 mb-2">{getCategoryName(x)}</span>
                                    </div>
                                    <div className="ms-4">
                                        <img className="rounded-2 w-11" src={x.app_icon} alt="logcenter" />
                                    </div>
                                </div>
                                <p className="text-body p-5 pb-0 pt-2" dangerouslySetInnerHTML={{ __html: x.desc }}></p>
                                <p className="mb-0 text-body px-5 pt-0 pb-4 text-primary">Read more <i className="me-2 uil-arrow-right fs-lg"></i></p>
                            </a>
                        </div>)}
                        {appList.filter(x => x.appCode === "SmartCheck").map((x, idx) => <div className="col-md-6 col-lg-4" key={`apps_${idx}`}>
                            <a href="#" className="card shadow-none lift h-100" onClick={(e) => moveTo(history, e, `/${x.appCode}`)}>
                                <img className="card-img-top" src="/assets/img/img_thumb_rf.png" alt="" />
                                <div className="p-5 pb-0 d-flex flex-row">
                                    <div className="me-auto">
                                        <h3 className="mb-1">{x.appName}</h3>
                                        <span className="badge bg-converter rounded py-1 mb-2">{getCategoryName(x)}</span>
                                    </div>
                                    <div className="ms-4">
                                        <img className="rounded-2 w-11" src={x.app_icon} alt="logcenter" />
                                    </div>
                                </div>
                                <p className="text-body p-5 pb-0 pt-2" dangerouslySetInnerHTML={{ __html: x.desc }}></p>
                                <p className="mb-0 text-body px-5 pt-0 pb-4 text-primary">Read more <i className="me-2 uil-arrow-right fs-lg"></i></p>
                            </a>
                        </div>)}
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <p className="text-center"><img src="/assets/img/logo-light.png" className="rounded-2 w-10" alt="RAYTeams" /></p>
                            <h2 className="display-6 mb-6 text-center">All services to dental, RAYTeams.</h2>
                            <p className="text-center mb-6 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p className="text-center"><a className="btn btn-md btn-primary rounded-pill text-white text-decoration-none mb-1" onClick={(e) => moveTo(history, e, "/aboutcloud")}>RAYTeams Download</a> </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(Apps);
