import AWS from 'aws-sdk';
import axios from 'axios';
import { getEnv } from './/function';
import { AppConfig } from '../config';

export const GetDdb = () => {
  const ddb = new AWS.DynamoDB.DocumentClient();
  return ddb;
};

export const DoDB = async (type, data, region = "ap-northeast-2") => {
	const ret = await axios.post(getEnv() === "development" ? 
		"https://api-teams-info.raydevelop.com/common/dynamo" : 
		"https://api-teams-info.rayteams.com/common/dynamo", { data : {
			type,
			region,
			params : data
		} });
	return ret?.data;
}

/*
export const ScanDdb = async (params, ddb) => {
  if (!ddb) {
    ddb = GetDdb();
  }
  let ret = await ddb.scan(params).promise();
  let result = ret?.Items;
  let LastEvaluatedKey = ret.LastEvaluatedKey;
  while (LastEvaluatedKey) {
    ret = await ddb
      .scan({
        ...params,
        ExclusiveStartKey: LastEvaluatedKey,
      })
      .promise();
    result = [...result, ...ret.Items];
    LastEvaluatedKey = ret.LastEvaluatedKey;
  }
  return result;
};

export const QueryDdb = async (params, ddb) => {
  if (!ddb) {
    ddb = GetDdb();
  }
  let ret = await ddb.query(params).promise();
  let result = ret?.Items;
  let LastEvaluatedKey = ret.LastEvaluatedKey;
  while (LastEvaluatedKey) {
    ret = await ddb
      .query({
        ...params,
        ExclusiveStartKey: LastEvaluatedKey,
      })
      .promise();
    result = [...result, ...ret.Items];
    LastEvaluatedKey = ret.LastEvaluatedKey;
  }
  return result;
};

export const fetchData = async (tableName, sfilter, ddb) => {
	if (!ddb) {
		ddb = GetDdb();
	}
	var params = {
		TableName: tableName,
		ScanFilter : sfilter
	}
	const scanResults = { Items : [], Count : 0 };
	let items;
	do{
		items =  await ddb.scan(params).promise();
		items.Items.forEach((item) => { scanResults.Items.push(item); scanResults.Count++; });
		params.ExclusiveStartKey  = items.LastEvaluatedKey;
	}while(typeof items.LastEvaluatedKey !== "undefined");

	return scanResults;
}

export const updateData = async (tableName, key, data, ddb) => {
	if (!ddb) {
		ddb = GetDdb();
	}
	var attr = {};
	Object.keys(data).map((x) => {
		attr[x] = {
			Action: "PUT",
			Value: data[x],
		};
	});
    var params = {
        TableName: tableName,
		Key: key, 
		AttributeUpdates: attr
    }
    await ddb.update(params).promise();
}
*/