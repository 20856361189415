import axios from 'axios';
import { JWT } from '../../config';
import { getApiUrl } from '../../lib/function';
import { getRegion } from '../../constant/country';
import { Store } from '../../store';
import { AuthConstant, GroupConstant, AlertConstant, SubscriptionConstant } from '../reducer';

const GetAllSubscriptions = (id) => async (dispatch) => {
	console.log("GetAllSubscriptions");
	const API_URL = getApiUrl('payment', "ap-northeast-2");
	const ret = await axios.get(API_URL + '/usersubscriptions', JWT());
	console.log("GetAllSubscriptions", ret);
	if (ret.data.status === 'success') {
		dispatch({ type: SubscriptionConstant.SUB_GETALL, items: ret.data.data });
    }else{
		dispatch({ type: AlertConstant.ERROR, data: ret.data.err });
    }
}

const GetAllPayments = async (id) => {
	const API_URL = getApiUrl('payment', "ap-northeast-2");
	const ret = await axios.get(API_URL + '/userpayments', JWT());
	if (ret.data.status === 'success') {
		return ret.data.data;
    }else{
		return [];
    }
};

const GetSubAllPayments = async (subid) =>  {
	const API_URL = getApiUrl('payment', "ap-northeast-2");
	const ret = await axios.post(API_URL + '/usersubpayments', { data : { subid }}, JWT());
	if (ret.data.status === 'success') {
		return ret.data.data;
    }else{
		return [];
    }
};

export const SubscriptionAction = {
    GetAllSubscriptions,
    GetAllPayments,
	GetSubAllPayments,
};
