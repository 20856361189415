const Privacy20230815_ko = ({ date }) => {

    return <>
        <section className="wrapper bg-gray">
            <div className="container pt-6 pt-md-12 pb-14">
                <div className="row gx-0">
                    <div className="col-xl-12">
                        <section className="wrapper policy">
                            <h1 className="display-3 mb-6">개인정보처리방침 
                            </h1>
                            <h4 className="text-muted">시행기간: 2022년 8월 15일 ~ 2023년 9월 30일</h4>
                            <div className="bg-light p-10 pt-5 pb-5 rounded">
                                <h4 className="pt-5">1.	개인정보처리방침의 의의</h4>
                                <p>주식회사 레이(이하 “회사”)는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 준수하고 이용자의 개인정보를 보호하기 위해 최선을 다하고 있습니다. 회사는 이용자의 개인정보를 중요시하며, 개인정보처리방침을 통하여 회사가 이용자로부터 제공받는 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보 보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다.</p>
                                <p>본 개인정보처리방침은 본 방침이 게시된 웹사이트(https://www.rayteams.com), 그리고 소프트웨어 및 등록과정에서 회사가 이용자의 개인정보를 수집, 사용, 공개, 이전 및 저장하는 방법과 이용자의 개인정보를 보호하기 위해 취하는 조치를 뜻하며, 레이팀즈 및 관련 제반 서비스(모바일 웹/앱 포함)에 적용됩니다.</p>
                                <p>본 방침은 2022년 8월 19일부터 시행됩니다. 추후 본 방침을 개정하는 경우 웹사이트 공지사항 또는 이메일 등의 방법을 통하여 공지하겠습니다.</p>
                                <h4 className="pt-5">2.	수집하는 개인정보 및 수집방법</h4>
                                <p>서비스를 이용하기 위해 회원 가입을 할 경우, 회사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.</p>
                                <ul>
                                    <li><strong>회원가입 시점에 회사가 이용자로부터 수집하는 개인정보는 아래와 같습니다.</strong><br />
                                        <p className="pt-2">회사는 연락처 데이터베이스를 관리하기 위하여 귀하의 이름, ID(이메일), 전화번호, 치과 이름, 기공소 이름 및 주소를 수집하고 있습니다.<br />
                                            회사는 귀하가 자발적으로 이메일이나 다른 경로로 제공한 정보에 대해 접근/수집만 할 수 있으며, 귀하에 관한 개인정보 수집과 관련된 사항은 적용되는 법률에 따라 통보됩니다. 회사는 이 정보를 누구에게도 판매하거나 대여하지 않습니다.</p>
                                    </li>
                                    <li><strong>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다. </strong><br /><br />
                                        <table className="table">
                                            <colgroup>
                                                <col width="8%" />
                                                <col width="12%" />
                                                <col width="40%" />
                                                <col width="40%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>유형</th>
                                                    <th>구분</th>
                                                    <th>수집/이용 항목</th>
                                                    <th>수집/이용 목적</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center" rowSpan="3">필수</td>
                                                    <td className="text-center" rowSpan="2">회원 공통</td>
                                                    <td>이름, ID(이메일), 비밀번호, 전화번호, 치과 또는 기공소명, 주소</td>
                                                    <td>이용자 식별, 계약이행을 위한 연락, 서비스 이용에 따른 정보 제공(고지사항 전달, 본인의사 확인, 서비스관련상담, 민원사항 처리, 유의사항 등), 서비스 만족도 조사</td>
                                                </tr>
                                                <tr>
                                                    <td>이름, ID(이메일), 서비스이용기록, 아이피 주소(IP Address), 접속로그</td>
                                                    <td>부정거래방지</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">치과/기공소<br />구성 회원</td>
                                                    <td>이름, ID(이메일), 비밀번호, 전화번호, 치과 또는 기공소명, 주소</td>
                                                    <td>이용자 식별, 중복가입방지, 계약이행을 위한 연락, 서비스 이용에 따른 정보 제공(고지사항 전달, 본인의사 확인, 서비스관련상담, 민원사항 처리, 유의사항 등), 서비스 만족도 조사</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p>추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용 목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다. </p>
                                    </li>
                                    <li><strong>서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.</strong>
                                        <p className="pt-2">구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환하여 수집합니다. <br />
                                            이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다. </p>
                                    </li>
                                    <li><strong>회사는 아래의 방법을 통해 개인정보를 수집합니다. </strong>
                                        <ul>
                                            <li>회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. </li>
                                            <li>고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다. </li>
                                            <li>오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다. </li>
                                            <li>회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 개인정보보호법에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 회사에 제공합니다. </li>
                                            <li>기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다. </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h4 className="pt-5">3.	수집한 개인정보의 이용 </h4>
                                <p>회사가 수집한 개인정보는 다음과 같은 목적에 활용됩니다. 회사는 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발, 제공 및 향상, 안전한 인터넷 환경 구축 등 아래의 목적으로만 개인정보를 이용합니다. </p>
                                <ul>
                                    <li>회원 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용</li>
                                    <li>이용자의 질문에 관한 답변 제공 등 회원 대응 </li>
                                    <li>통계 및 모니터링 목적 </li>
                                    <li>컨텐츠 등 기존 서비스 제공에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석 등 기존 서비스 개선 </li>
                                    <li>분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 기타 마케팅 계획 수립 </li>
                                    <li>약관 개정 등의 고지사항 전달 </li>
                                    <li>법령 및 회사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지</li>
                                    <li>보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용 환경 구축</li>
                                </ul>
                                <p>회사는 사전 동의가 얻어진 경우에만 귀하의 개인 데이터를 사용하여 마케팅 자료를 발송합니다. </p>
                                <h4 className="pt-5">4.	개인정보의 제공 및 위탁 </h4>
                                <p>회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 회사에 개인정보 제출 의무가 발생한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인정보를 제공하고 있습니다. </p>
                                <p>회사는 귀하의 요청을 이행하는 데 필요한 경우와 법률에 의거하여 귀하의 정보 제공이 요청된 경우, 정보주체의 별도의 동의를 받은 경우, 통계작성 및 학술연구 등의 목적을 위해 비식별화 처리하여 제공하는 경우, 조약과 그 밖의 국제협정의 이행을 위하여 외국정부 등에 제공하기 위하여 필요한 경우, 범죄의 수사 및 재판 업무 수행, 보호처분 집행을 위하여 필요한 경우를 제외하고 귀하의 정보를 회사 외부의 제3자와 공유하지 않습니다. </p>
                                <p>회사는 서비스 제공을 위해 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며 위탁 받은 업체가 개인정보를 안전하게 처리하도록 필요한 사항을 개인정보처리위탁계약서를 통해 규정하고 관리 및 감독을 하고 있습니다. 개인정보의 위탁은 회원 탈퇴 및 계약해지 시 종료됩니다. </p>
                                <p>개인정보 수탁자 및 위탁업무 내용은 아래와 같고, 필요한 경우 수탁업체가 추가되거나 변경될 수 있으며, 이 경우 본 개인정보 처리방침에 변경 사항을 반영하여 고지합니다. </p>
                                <table className="table">
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="40%" />
                                        <col width="40%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>수탁자</th>
                                            <th>위탁사무 및 목적</th>
                                            <th>보유 및 이용기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">Amazon Web Services, Inc. </td>
                                            <td className="text-center">데이터 보관 업무</td>
                                            <td className="text-center">목적 달성 후 즉시 파기</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 className="pt-5">4.1 개인정보의 국외이전</h4>
                                <p>개인정보 처리위탁 중 국외법인에서 처리하는 위탁업무는 아래와 같습니다. </p>
                                <p>회사는 재난, 재해 상황에도 중단 없는 서비스 제공을 위해 국내외에 데이터를 안전하게 백업(보관)합니다. 국외 데이터 보관 업무를 처리하는 AWS는 회사의 엄격한 통제 하에 데이터 관리 업무를 수행합니다. </p>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>이전되는<br />개인정보의<br />항목</th>
                                            <th>이전되는 국가</th>
                                            <th>이전 일시</th>
                                            <th>이전 방법</th>
                                            <th>이전받는 자</th>
                                            <th>이전받는 자의 정보<br />관리책임자 연락처</th>
                                            <th>이전받는 자의<br />개인정보 이용 목적</th>
                                            <th>이전받는 자의<br />개인정보 보유 및 이용기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">서비스 이용 중 수집되는 개인정보 및 로그 정보</td>
                                            <td>
                                                1. 미국 (오하이오, 버지니아, 캘리포니아, 오레곤)<br />
                                                2. 인도 (뭄바이)<br />
                                                3. 한국 (서울)<br />
                                                4. 싱가폴<br />
                                                5. 호주 (시드니)<br />
                                                6. 일본 (도쿄)<br />
                                                7. 캐나다<br />
                                                8. 독일 (프랑크푸르트)<br />
                                                9. 아일랜드<br />
                                                10. 영국 (런던)<br />
                                                11. 프랑스 (파리)<br />
                                                12. 스웨덴 (스톡홀름)<br />
                                                13. 바레인<br />
                                                14. 브라질 (상파울로)
                                            </td>
                                            <td className="text-center">서비스 제공 과정 중 수시로 이전</td>
                                            <td className="text-center">온라인</td>
                                            <td className="text-center">AWS</td>
                                            <td className="text-center">AWS Korea Privacy<br />aws-korea-privacy@amazon.com </td>
                                            <td className="text-center">RAYTeams 서비스 제공을 위한 데이터 저장</td>
                                            <td className="text-center">이용자의 서비스 가입기간 동안 보유</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 className="pt-5">5.	개인정보의 보유기간 및 파기 </h4>
                                <ul>
                                    <li><strong>개인정보의 보유기간</strong><br />
                                        <p className="pt-3">회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다. 다만, 회사는관계 법령의 규정 및 회사 내부 방침에 의하여 개인정보를 보존할 필요가 있는 경우 일정한 기간 동안 해당 개인정보를 보관합니다</p>
                                        <p>또한, 회사는 개인정보보호법 제39조의6 및 같은 법 시행령 제49조의5에 따라, 1년간 또는 이용자가 지정한 기간 동안 서비스 이용 기록이 없는 경우 해당 이용자를 휴면회원으로 전환하며, 휴면회원으로 전환될 경우 관련된 정보를 파기하거나 별도의 DB에 분리 보관합니다. </p>
                                        <p>회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 개인정보 파기에 대해서는 개인정보가 파기되는 사실, 파기 예정일 및 파기되는 개인정보의 항목을, 분리 보관에 대해서는 별도 분리 보관되는 사실 및 분리 보관 예정일, 별도 분리 보관하는 개인정보 항목을 이메일로 알리겠습니다. </p>
                                        <p>단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보의 보관의무를 규정하는 경우는 아래와 같습니다. 회사는 이 기간 동안 법령의 규정에 따라 개인정보를 보관합니다. </p>
                                        <ul>
                                            <li>가.	관계법령의 규정이 있는 경우
                                                <ul className="list-unstyled">
                                                    <li>1)	계약 또는 청약철회 등에 관한 기록: 5년  (전자상상거래 등에서의 소비자보호에 관한 법률)</li>
                                                    <li>2)	대금결제 및 재화 등의 공급에 관한 기록: 5년  (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                                                    <li>3)	소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                                                    <li>4)	이용자의 로그기록, 접속지 추적자료, 웹사이트 방문기록: 3개월 (통신비밀보호법)</li>
                                                    <li>5)	전자금융거래법에 의한 전자금융에 관한 기록: 5년 (전자문서 및 전자거래 기본법) </li>
                                                </ul>
                                            </li>
                                            <li>나.	회사 내부 방침에 따라 보유하는 개인정보의 보존 기간
                                                <ul className="list-unstyled">
                                                    <li>1)	개별적으로 이용자의 동의를 받은 경우: 동의를 받은 기간 </li>
                                                    <li>2)	서비스 부정 가입 및 이용 식별을 위해 필요한 정보(아이디, 기기정보, 서비스 이용 로그 및 수집 항목, 탈퇴일시, 탈퇴사유): 관련 분쟁 해결 시점까지 </li>
                                                    <li>3)	회사와 이용자 간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우: 해당 분쟁 해결 시점까지 </li>
                                                    <li>4)	탈퇴회원 개인정보: 탈퇴일로부터 3개월 </li>
                                                    <li>5)	콜센터 및 홈페이지 A/S 접수, 상담 시 수집된 개인정보: 수집일로부터 5년 </li>
                                                    <li>6)	홈페이지 문의 시 수집된 개인정보: 수집일로부터 3년 </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="pt-5"><strong>개인정보의 파기</strong><br />
                                        <p className="pt-3">회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다.<br />
                                            회사는 법령 등에 따라 보관하는 경우, 별도의 데이터베이스로 전송되며 법률에 의해 제공되지 않은 한 보유 목적으로만 사용되며 명시된 기간 내에 폐기됩니다. <br />
                                            전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다. </p>
                                    </li>
                                </ul>
                                <h4 className="pt-5">6.	이용자 및 법정대리인의 권리와 행사방법</h4>
                                <p>귀하는 다음과 같은 정보보호 권리를 가집니다:</p>
                                <ul>
                                    <li>귀하는 개인정보의 접근, 수정, 업데이트, 처리정지 또는 삭제를 요청할 수 있습니다. 그러나, 회사는 법률에 특별한 조항이 있거나 법적 의무를 준수할 필요가 있는 경우, 혹은 다른 사람을 해할 위험이 있거나 타인의 재산이나 이익을 부당하게 침해할 위험이 있는 경우 귀하의 요청을 유예할 수 있습니다. </li>
                                    <li>귀하는 귀하의 개인정보 처리에 이의를 제기할 수 있으며, 귀하의 개인정보 처리를 제한하도록 요청할 수 있습니다. </li>
                                    <li>회사가 귀하의 동의 하에 귀하의 개인정보를 수집하고 처리한 경우 귀하는 언제든지 귀하의 동의를 철회할 수 있습니다.<br />귀하의 동의 철회는 철회 이전에 수행한 모든 처리의 적법성에 영향을 미치지 않으며, 동의가 아닌 합법적인 처리 근거에 의거하여 수행된 귀하의 개인정보 처리에도 영향을 미치지 않습니다.<br />
                                        이 권리를 행사하고자 하는 고객은 support@rayteams.com로 이메일을 발송하거나 “경기도 성남시 분당구 판교역로 221 주식회사 레이”로 우편을 발송해 귀하의 정보 변경, 수정, 혹은 통지 요청을 할 수 있습니다.  <br />
                                        빠른 기간 내에 귀하의 요청에 답변 드리겠습니다.
                                    </li>
                                </ul>
                                <h4 className="pt-5">7.	개인정보보호를 위한 회사의 노력</h4>
                                <p>회사는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하고 있습니다. </p>
                                <p>회사는 관리적, 기술적, 물리적 조치를 취하여 수집된 개인정보가 제3자에 의해 무단으로 접근, 사용 또는 공개되지 않도록 귀하의 개인정보를 보호하고 있습니다. 회사는 데이터 보호를 위해 내부관리계획을 수립하고 실행 중에 있습니다. 귀하가 제공하는 개인정보는 허가 받지 않은 접근, 사용 또는 공개로부터 보호되며 서버에 안전하게 보관됩니다. </p>
                                <p>추가적인 기술적 조치로는 개인정보 처리시스템에 대한 접근 권한 관리, 고유한 식별정보 암호화, 보안 프로그램 설치, 접속기록의 보관, 암호화 등이 있습니다. </p>
                                <ul>
                                    <li className="pt-5"><strong>개인정보보호 내부관리계획을 수립, 시행하고 있습니다.</strong>
                                        <p className="pt-3">개인정보 보호책임자의 지정 등 개인정보 보호 조직의 구성 및 운영에 관한 사항을 포함하여 개인정보 내부관리계획을 수립하고, 매년 내부관리계획을 잘 시행하고 있는지를 점검하고 있습니다. </p></li>
                                    <li className=""><strong>개인정보에 대한 접근 통제 및 접근 권한 제한 조치를 취하고 있습니다.</strong>
                                        <p className="pt-3">개인정보에 대한 불법적인 접근을 차단하기 위해 개인정보 처리시스템에 대한 접근 권한의 부여, 변경, 말소 등에 관한 기준을 수립하여 시행하고 있으며, 침입차단시스템을 설치, 운영하고 있습니다. 또한 개인정보를 처리하는 직원을 최소한으로 관리하며, 개인정보처리시스템에서 개인정보의 다운로드가 가능한 직원들의 업무용 PC에 대해 외부 인터넷망과 내부망을 분리하여 개인정보유출을 줄이고 있습니다.</p></li>
                                    <li className=""><strong>개인정보를 안전하게 저장, 전송할 수 있는 암호화 조치를 취하고 있습니다.</strong>
                                        <p className="pt-3">법령에서 암호화를 요구하고 있는 비밀번호, 고유식별정보 외에 이메일, 주소, 전화번호등을 추가로 암호화하여 보관하고 있습니다. 또한 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 송수신하고 있습니다. </p></li>
                                    <li className=""><strong>개인정보 접속기록의 보관 및 위조, 변조 방지를 위한 조치를 취하고 있습니다.</strong></li>
                                </ul>
                                <h4 className="pt-5">8.	개인정보의 국외이전 </h4>
                                <p>회사는 서비스 제공 목적 및 이용자의 편의 증진 등을 위하여 이용자의 정보를 국외로 전송하거나, 국외에서 관리할 수 있습니다. <br />
                                    이 개인정보 보호정책은 개인정보를 다른 국가로 이전하는 경우에도 적용됩니다. 회사는 개인정보의 국외 이전의 경우 귀하의 동의를 득하며, 귀하의 개인정보가 보호되도록 적절한 보호 조치를 취합니다.
                                </p>
                                <h4 className="pt-5">9.	쿠키정책</h4>
                                <p>당사는 귀하가 서비스에 최대한 쉽고, 효율적이고, 유용하게 접근할 수 있기를 바랍니다. 이를 위해 당사는 쿠키 및 유사한 기술을 사용하여 사용자 환경을 개선하고, 사이트 보안을 향상합니다.</p>
                                <p>쿠키는 귀하가 거의 모든 웹사이트를 방문할 때 귀하의 기기에 자동으로 생성되는 작은 텍스트 파일입니다. 쿠키는 귀하의 인터넷 브라우저에 의해서 저장되며, 귀하의 인터넷 사용에 대한 기본 정보를 포함합니다. 귀하가 웹사이트를 다시 방문할 때마다 웹사이트에서 귀하의 기기를 인식하고, 개인화된 콘텐츠를 제공하여, 사용자 환경을 개선할 수 있도록 귀하의 브라우저에서 쿠키를 웹사이트에 다시 전송합니다. 또한, 당사는 귀하의 로그인 정보를 저장하는 데 쿠키를 사용하므로, 귀하는 서비스를 이용할 때 로그인 정보를 반복해서 다시 입력할 필요가 없습니다. 그 밖의 쿠키를 사용하여 귀하의 선호도를 파악할 수 있습니다.</p>
                                <p>당사에서는 필수 쿠키만 사용합니다. 필수 쿠키는 웹사이트에 액세스하여 둘러보고 웹사이트의 모든 기능을 이용하는 데 필요합니다. 이러한 쿠키가 없으면 웹사이트가 제대로 작동하지 않으므로 중요한 특정 기능을 이용할 수 없습니다. 예를 들어, 당사에서는 방문 중에 로그인 상태를 유지하는 데 쿠키를 사용하므로, 사이트에서 다른 페이지에 액세스하기 위해 반복적으로 로그인할 필요가 없습니다. 또한, 당사는 부정행위를 감지하고 예방하기 위해 필수 쿠키를 사용할 수 있습니다. 필수 쿠키는 웹사이트의 기능에 액세스하여 이를 이용하는 데 필요하므로, 쿠키 동의 도구를 통해 해제할 수 없습니다. </p>
                                <h4 className="pt-5">10. 개인정보 보호책임자 및 열람청구 접수/처리 부서 안내 </h4>
                                <p>회사는 귀하의 개인정보를 보호하고 개인정보와 관련한 민원을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 귀하는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 당사 개인정보 보호책임자에게 신고하실 수 있습니다. 개인정보 보호책임자가 귀하의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
                                <ul>
                                    <li><strong>개인정보 보호책임자</strong></li>
                                    <li className="pt-1">이름: 이민규 </li>
                                    <li>소속 및 직책: 소프트웨어 개발본부 </li>
                                    <li>직위: 상무 </li>
                                    <li>이메일: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a></li>
                                </ul>
                                <ul>
                                    <li><strong>개인정보 보호담당자</strong></li>
                                    <li className="pt-1">이름: 방성원</li>
                                    <li>소속:  소프트웨어 개발본부</li>
                                    <li>직위: 수석</li>
                                    <li>전화: +82-31-605-1000</li>
                                    <li>이메일: <a href="mailto:privacy@rayteams.com">privacy@rayteams.com</a></li>
                                </ul>
                                <p>기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다. <br />
                                    개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr/" target="_blank">privacy.kisa.or.kr</a> / 국번없이 118)<br />
                                    대검찰청 사이버수사과 (<a href="https://www.spo.go.kr" target="_blank">www.spo.go.kr</a> / 국번없이 1301)<br />
                                    경찰청 사이버안전지킴이 (<a href="http://www.police.go.kr/www/security/cyber.jsp" target="_blank">http://www.police.go.kr/www/security/cyber.jsp</a> / 국번없이 182)
                                </p>
                                <h4 className="pt-5">11. 개정 전 고지의무</h4>
                                <p>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지할 것입니다.<br />
                                    다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다. </p>
                                <p>공고일자: 2022년 8월 10일<br />
                                    시행일자: 2022년 8월 15일
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
	</>
}
export default Privacy20230815_ko;
