import { useEffect, useMemo } from 'react';
import { AppAction } from '../redux/action';
import Header from './Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../lib/function';
import { history } from '../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../constant/appconst';
import { useState } from 'react';
import { DoDB } from '../lib/ddb';

const LoadPreset = async () => {
    try {
        const payload = {
            type: "query",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                KeyConditionExpression: "#name = :name and #type = :type",
                ExpressionAttributeNames: { "#name": "name", "#type": "type" },
                ExpressionAttributeValues: { ":name": "homepage", ":type": "preset" },
            },
        };
        const list = await DoDB(payload.type, payload.params, payload.region);
        return list[0] ?? {};
    } catch (error) {
        return {};
    }
};

const AppList = ({ appList, GetAppList }) => {
    const { t, i18n } = useTranslation(['translation']);
    const [visibleList, setVisibleList] = useState([]);
    const hasEIFUDocs = useMemo(() => appList.some(x => x?._app_info?.manualRevisions?.length > 0), [appList]);
    const [preset, setPreset] = useState({});
    const appPageApps = useMemo(() => preset?.appPageApps || [], [preset?.appPageApps]);

    useEffect(() => {
        LoadPreset().then(setPreset);
    }, []);

    const initVisibleList = async (_list) => {
        let _apps = _.cloneDeep(_list);
        const newOrderedApps = [];
        for (const _name of appPageApps) {
            const target = _apps.find(x => x.appCode === _name);
            if (target) {
                newOrderedApps.push(target);
                _apps = _apps.filter(x => x.appCode !== _name);
            }
        }
        const result = [...newOrderedApps, ..._apps];
        setVisibleList(result);
        
        // setVisibleList(_list);

        // "RAYDENT", "rayface", "RAYFusion"
        //const visibleInAppFeature = await payableSQA()
        //if (!visibleInAppFeature) {
        //    setVisibleList(_list.filter(x => x.category !== "inAppFeature"));
        //} else {
        //    const list2 = getIsSubscribableKr();
        //    if(list2){
        //        setVisibleList(_list);
        //    }else{
        //        setVisibleList(_list.filter(x => x.category !== "inAppFeature"));
        //    }
        //}
    };

    useEffect(() => {
        initVisibleList(appList.filter(x => x.appCode !== "ExoplanConverter"))
    }, [appList]);

    useEffect(() => {
        GetAppList('');
    }, []);

    const getCategoryName = (x) => {
        const contCategory = APPCATEGORY.find(y => y.value === x.category);
        return contCategory?.label || x.category;
    };

    return (
        <>
            <Header />
            <section className="wrapper app-ban-area d-flex" data-image-src="/assets/img/img_app_ban01.jpg" style={{ "backgroundImage": "url(/assets/img/img_app_ban01.jpg)", "height": "400px" }}>
                <div className="container align-items-center d-flex">
                    <div className="row pt-md-12">
                        <div className="col-lg-12 z-index-9">
                            <h2 className="display-3 mb-6 fw-normal text-white">{t("Powerful Apps available for Dental Service")}</h2>
                            <div className="fs-lg mb-6 text-white">
                                <p className="mb-0 opacity-75">{t("Variety of apps for an effective and convenient.")}</p>
                                <p className="mb-0 opacity-75">{t("Install, manage and integrate apps through RAYTeams!")}</p>
                            </div>
                            {/*<div className="row gy-3 gx-xl-8">
                                <div className="col-xl-12">
                                    <a href="#" className="btn btn-md btn-outline-white rounded-pill w-20">Read More</a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-apps apps-list">
                <div className="container py-12">
                    <div className="row gy-6">
                        {visibleList.map((x, idx) => <div className="col-md-6 col-lg-4" key={`apps_${idx}`}>
                            <a href="#" className="card shadow-none lift h-100" onClick={(e) => moveTo(history, e, `/${x.appCode}`)}>
                                <div className="p-5 pb-0 d-flex flex-row">
                                    <div className="me-auto">
                                        <h3 className="mb-1">{x.appName}</h3>
                                        <span className="badge bg-converter rounded py-1 mb-2">{getCategoryName(x)}</span>
                                    </div>
                                    {x.app_icon && <>
                                        <div className="ms-4">
                                            <img className="rounded-2 w-11" src={x.app_icon} alt={x.appName} />
                                        </div>
                                    </>}
                                </div>
                                <p className="text-body p-5 pb-0 pt-2" dangerouslySetInnerHTML={{ __html: x.desc }}></p>
                                <p className="mb-0 text-body px-5 pt-0 pb-4 text-primary">Read more <i className="me-2 uil-arrow-right fs-lg"></i></p>
                            </a>
                        </div>)}
                    </div>
                    {visibleList?.length > 0 && hasEIFUDocs && <>
                        <div className='d-flex align-items-center justify-content-end mt-3'>
                            <div>
                                <span className="btn btn-xs btn-outline-black rounded-pill d-flex align-items-center gap-2" onClick={() => history.push("/app-manuals")}>
                                    <span className='fw-bolder'>eIFU</span> - {t('show all manuals')}
                                </span>
                            </div>
                        </div>
                    </>}
                </div>
            </section>
            {/*<section className="wrapper">
                <div className="container pb-11 pt-11 pt-md-14">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <p className="text-center"><img src="/assets/img/logo-light.png" className="rounded-2 w-10" alt="RAYTeams" /></p>
                            <h2 className="display-6 mb-6 text-center">All services to dental, RAYTeams.</h2>
                            <p className="text-center mb-6 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p className="text-center"><a className="btn btn-md btn-primary rounded-pill text-white text-decoration-none mb-1" onClick={(e) => moveTo(history, e, "/aboutcloud")}>RAYTeams Download</a> </p>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(AppList);
