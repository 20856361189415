import { useEffect } from 'react';
import { AppAction } from '../../redux/action';
import Header from '../Header';
import { connect } from "react-redux";
import { moveTo, payableSQA, getIsSubscribableKr } from '../../lib/function';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { APPCATEGORY } from '../../constant/appconst';
import { useState } from 'react';
import { BoardAction } from '../../redux/action/BoardAction';

const SupportFAQ = ({ }) => {

    const { t, i18n } = useTranslation(['translation']);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getData();
    }, [i18n.language]);

    const getData = async () => {
        const ret = await BoardAction.GetSupportBoardAllContentsTop(i18n.language, 3)
        setItems(ret);
        console.log(ret)
    }

    return <section className="wrapper bg-light help-center">
        {items.length > 0 && <div className="container py-10 pb-10">
            <div className="row align-items-center mb-6">
                <div className="col-md-8 col-lg-9 col-xl-8 col-xxl-7 pe-xl-20">
                    <h4 className="display-6 py-6 pb-0">{t("자주 찾는 질문")}</h4>
                </div>
                {/*<div className="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
                    <a href="#" className="more hover link-primary">{t("Learn More")}</a>
                </div>*/}
            </div>
            <div className="row">
                {items.map((x, idx) => <div className="col-lg-4 mb-8" key={idx}>
                    <div className="d-flex flex-row">
                        <div>
                            <div className="icon btn btn-circle disabled btn-soft-primary me-4"> <i className="uil uil-comments"></i> </div>
                        </div>
                        <div className="freq_list">
                            <a href style={{ cursor : "pointer" }} onClick={() => history.push(`/support/view/${x._id}`)} className="hover"><h4>{x.title}</h4></a>
                            <div className="mb-2" style={{
                                height: "80px",
                                overflowY: "hidden"
                            }} dangerouslySetInnerHTML={{ __html: x.content }}></div>
                            <a href style={{ cursor : "pointer" }} 
                                onClick={() => history.push(`/support/view/${x._id}`)}
                                className="more hover text-secondary">Learn More</a>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>}
    </section>
}

const mapState = (state) => {
    const appList = state.AppReducer.appList;
    return { appList };
}

const mapDispatch = dispatch => ({
    GetAppList: (lang) => dispatch(AppAction.GetAppList(lang)),
})

export default connect(mapState, mapDispatch)(SupportFAQ);
