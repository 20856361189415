import Header from "./Header"
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { AppAction } from "../redux/action";
import { useParams } from "react-router-dom";
import AppHeader from "./AppHeader";
import { useTranslation } from 'react-i18next';
import { DownloadApp, getRealAppName } from "../lib/function";
import { history } from "../history";
import { convertHnElsToTreeByHTMLString } from "../lib/domFunction";

const Releasenotes = ({ appTitle, appIcon, isForceUpdate, releasenotes, GetAppInfo, GetReleaseNotes, app, published }) => {
    const { t, i18n } = useTranslation(['translation']);
    const [lang, setLang] = useState(i18n.language || "en");
    const [contents, setContents] = useState({});
    const [list, setList] = useState([]);
    const [downloading, setDownLoading] = useState(false);
    const { appCode, version } = useParams();
    const user = localStorage.getItem('user');
    const [realAppName] = useState(getRealAppName(appCode));

    useEffect(() => {
        if (!lang)
            return;

        GetAppInfo(realAppName || 'RayLink');
        GetReleaseNotes(realAppName || 'RayLink', lang);
    }, [realAppName, lang]);

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        if (releasenotes && releasenotes.length > 0) {
            setList(releasenotes?.map(x => {
                const { hierarchies, htmlString, elCount } = convertHnElsToTreeByHTMLString(x.contents);
                const result = h2ReplacedContents(htmlString);
                const summary = getSummary(result.contents);
                return {
                    ...x,
                    contents: x.contents ? summary.contents : null, // 존재하지 않는 contents의 경우 null로 적용함
                    subTitle: result.subTitle,
                    summary: summary.summary,
                    hierarchies,
                    elCount,
                };
            }));
        }
    }, [releasenotes]);

    useEffect(() => {
        if (list?.length > 0) {
            if (version) {
                if (list.find(x => x.version === version)) {
                    setContents(list.find(x => x.version === version));
                } else {
                    history.push(((appCode === 'RayLink' || !appCode) ? '' : '/' + appCode) + "/releasenotes");
                }
            } else {
                setContents(list[0]);
            }
        }
    }, [list, version]);

    const select = (v) => {
        const path = ((appCode === 'RayLink' || !appCode) ? '' : '/' + appCode) + '/releasenotes/' + v;
        history.push(path);
    }

    const h2ReplacedContents = (contents) => {
        const div = document.createElement("div");
        div.innerHTML = contents;
        const h2 = div.getElementsByTagName("h2");

        const subTitle = [];

        for (let i = 0; i < h2.length; i++) {
            if (h2[i].innerText.trim() !== '') {
                const id = h2[i].id || ('sub_title_' + i)
                h2[i].id = id;
                subTitle.push({ id, title: h2[i].innerText });
            }
        }

        return { contents: div.outerHTML, subTitle }
    }

    const getSummary = (contents) => {
        const div = document.createElement("div");
        div.innerHTML = contents;
        const h3 = div.getElementsByTagName("h3");

        const summary = [];

        for (let i = 0; i < h3.length; i++) {
            if (h3[i].innerText.trim() !== '') {
                const id = h3[i].id || ('summary_' + i)
                h3[i].id = id;
                summary.push({ id, title: h3[i].innerText });
            }
        }

        return { contents: div.outerHTML, summary };
    }

    const downloadStart = async () => {
        if (downloading)
            return;
        setDownLoading(true);
        await DownloadApp(realAppName);
        setDownLoading(false);
    }

    return (
        <>
            {appCode ? <AppHeader /> : <Header />}

            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: 'url("/assets/img/bg1.jpg")' }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={appIcon || '/assets/img/app-icon/img_rayteams.png'} alt={appTitle || 'RAYTeams Client'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{appTitle || 'RAYTeams Client'}</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('릴리즈 노트')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container manual">
                <div className="row">
                    <aside className="col-xl-3 order-xl-1 sidebar doc-sidebar py-md-8 py-6 d-xl-block order-1">
                        <div className="widget">
                            <h6 className="widget-title fs-17 mb-2">{t('릴리즈 노트')}</h6>
                            <hr />
                            <div className="accordion accordion-wrapper">
                                {list &&
                                    list.map((x, idx) => {
                                        return (
                                            <div className="accordion-item" key={'vlist_' + idx}>
                                                <div className="card-header" id="branch-1">
                                                    <button
                                                        className={x.version === contents.version ? '' : 'collapsed'}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#branch-collapse-1"
                                                        aria-expanded="true"
                                                        aria-controls="branch-collapse-1"
                                                        onClick={() => select(x.version)} >
                                                        <span>
                                                            {x.version}
                                                            {idx === 0 && <a className="badge bg-primary rounded-pill badge-xs">Latest</a>}
                                                        </span>
                                                    </button>
                                                </div>
                                                {
                                                    x.subTitle.length > 0 && x.version == contents.version &&
                                                    (
                                                        <div id="branch-collapse-1" className="card-body accordion-collapse collapse show" aria-labelledby="branch-1">
                                                            <nav id="collapse-style">
                                                                <ul className="list-unstyled fs-sm lh-sm text-reset">
                                                                    {
                                                                        x.subTitle.map((y, idx) => {
                                                                            return (
                                                                                <li key={'sub_' + idx}>
                                                                                    <a href={'#' + y.id} onClick={(e) => moveTo(history, e, location.pathname + '#test')}>{y.title}</a>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </aside>
                    {releasenotes?.length > 0 && <div className="col-xl-9 order-2 pt-md-8 pb-10">
                        <section className="wrapper">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">{appTitle || 'RAYTeams Client'}</a></li>
                                    <li className="breadcrumb-item"><a href="#">{t('릴리즈 노트')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{contents.version}</li>
                                </ol>
                            </nav>

                            <div className="applies-to-text-container">
                                <span className="publish-date-label">Released on</span>
                                <span className="publish-date">&nbsp;{published ? moment(published).format('LL') : moment(contents.released).format('LL')}</span>
                            </div>
                            <div className="row gx-lg-8 gx-xl-12 my-2 tit-release">
                                <div className={contents.version === releasenotes[0]?.version && contents?.downloadable ? "col-lg-8" : "col-lg-12"}>
                                    <h2 className="mb-1">
                                        <div className="d-flex algin-items-center gap-2">
                                            <div>
                                                {contents?.title || `v${contents?.version} Release Note`}
                                            </div>
                                            {contents.version === releasenotes[0]?.version && <div>
                                                <div className="badge bg-dark rounded-pill badge-xs">Latest</div>
                                            </div>}
                                        </div>
                                    </h2>
                                    <p className="mt-2 mb-4">
                                        {contents?.contents && <>
                                            {t('사용 편의성 증대를 위해 다음과 같은 기능이 추가 또는 변경되었습니다.')}<br />
                                            {t('변경된 내용을 확인 후 사용해 주세요.')}
                                        </>}
                                        {!contents?.contents && <>
                                            {t("Minor bugs have been fixed and usability has been improved.")}
                                        </>}
                                    </p>
                                </div>

                                {
                                    // TODO: app?.rayteams_apps 는 존재하지 않음
                                    (contents.version === releasenotes[0]?.version && contents?.downloadable && !app?.rayteams_apps) &&
                                    <div className="col-lg-4 text-end mt-lg-4 mb-4 mb-lg-0">
                                        {user && <a onClick={() => downloadStart()}>
                                            <div className="btn btn-sm btn-primary rounded-pill shadow-none no-transform mb-1">
                                                {downloading && t("Please wait a second...")}
                                                {!downloading && <><i className="uil uil-arrow-to-bottom me-2"></i>{contents.version} Download</>}
                                            </div>
                                        </a>}
                                        {!user && <a href onClick={() => history.push("/login")}>
                                            <div className="btn btn-sm btn-primary rounded-pill shadow-none no-transform mb-1">
                                                <i className="uil uil-arrow-to-bottom me-2"></i>{contents.version} Download
                                            </div>
                                        </a>}
                                    </div>
                                }
                            </div>
                            {contents?.contents && <>
                                <div className="board-content">
                                    <div className="card-body">
                                        {contents?.hierarchies?.length > 0 && contents?.elCount > 1 && <>
                                            <div className="card transparent bg-gray mb-8">
                                                <div className="card-body text-list">
                                                    <ol style={{ marginBottom: 0, listStyleType: 'none', paddingLeft: '1rem' }}>
                                                        {
                                                            contents.hierarchies?.map((x) => {
                                                                return (
                                                                    <li key={`s_${x.id}`}>
                                                                        <a href={`#${x.id}`}>{x.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                        {x?.children?.length > 0 && <>
                                                                            <ol style={{ listStyleType: 'none', paddingLeft: '1rem' }}>
                                                                                {x.children.map(y => <li key={y.id}>
                                                                                    <a href={`#${y.id}`}>{y.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                                    {y?.children?.length > 0 && <>
                                                                                        <ol style={{ listStyleType: 'none', paddingLeft: '1rem' }}>
                                                                                            {y.children.map(z => <li key={z.id}>
                                                                                                <a href={`#${z.id}`}>{z.contents}<i className="uil uil-angle-double-right"></i></a>
                                                                                            </li>)}
                                                                                        </ol>
                                                                                    </>}
                                                                                </li>)}
                                                                            </ol>
                                                                        </>}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ol>
                                                </div>
                                            </div>
                                        </>}
                                        <div dangerouslySetInnerHTML={{ __html: contents.contents }}></div>
                                    </div>
                                </div>
                            </>}
                        </section>
                        {/* <hr /> */}
                        <div className="quick-btn" style={{ display: 'none' }}>
                            <div className="row g-6">
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Check out the latest additions and updates to RAYTeams.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/community/" className="text-white" target="_blank">What's new</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Learn how to use RAYTeams and get answers to your questions.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/help-center/" className="text-white" target="_blank">Help Center</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-none bg-soft-yellow">
                                        <div className="card-body">
                                            <p className="card-text text-center text-navy">Still have more questions? Please contact us.</p>
                                            <div className="btn btn-yellow rounded-pill text-center w-100 shadow-none"><a href="/contact/#inquiry" className="text-white" target="_blank">1:1 Inquiry</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    return {
        appTitle: state.AppReducer.appInfo.appTitle,
        published: state.AppReducer.appInfo.published,
        appIcon: state.AppReducer.appInfo.appIcon,
        app: state.AppReducer.appInfo.app,
        isForceUpdate: state.AppReducer.appInfo.isForceUpdate,
        releasenotes: state.AppReducer.releasenotes
    };
}

const mapDispatch = dispatch => ({
    GetAppInfo: (appCode) => dispatch(AppAction.GetAppInfo(appCode, 'ko')),
    GetReleaseNotes: (appCode, lang) => dispatch(AppAction.GetReleaseNotes(appCode, lang))
})

export default connect(mapState, mapDispatch)(Releasenotes);
